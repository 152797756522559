import { Injectable } from '@angular/core';
import { Router, CanLoad, CanActivate, Route, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { TokenStorageService } from './token-storage.service';

@Injectable()
export class AuthGuardService implements CanLoad, CanActivate {

  constructor(private router: Router, private tokenStorage: TokenStorageService ) { }


  canLoad(route: Route): boolean {
    const accesToken = this.tokenStorage.getAccessTokenString();
    const refreshToken = this.tokenStorage.getRefreshTokenString();
    if ( accesToken !== null || refreshToken !== null ) {return true; }
    this.router.navigate(['/landing/index']);
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const accesToken = this.tokenStorage.getAccessTokenString();
    const refreshToken = this.tokenStorage.getRefreshTokenString();
    if ( accesToken !== null || refreshToken !== null ) {return true; }

    if (state.url.startsWith('/employees')) {
      this.router.navigate(['/employees/login']);
    } else {
      this.router.navigate(['/landing/index']);
    }
    return false;
  }
}
