import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { OrganizationSchema } from 'src/app/_schemas/organization-schema';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpService } from 'src/app/_services/http.service';

@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.scss']
})
export class AddOrganizationComponent implements OnInit {

  @Output() reloadOrganizationsEvent = new EventEmitter<OrganizationSchema>();

  // input
  polygonGeoJSON: string;
  organizationTypeId: number;

  // form fiels
  name: string;
  description: string;
  address: string;
  youtubePlayListId: string;
  domain: string;
  email: string;
  username: string;
  password: string;
  image: File;

  validMsgsEnvironment;

  // flags validation
  nameFieldValid: boolean;
  emailFieldValid: boolean;
  usernameFieldValid: boolean;
  passwordFieldValid: boolean;

  showProgressBar: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private httpService: HttpService
  ) { }

  ngOnInit() {
    this.showProgressBar = false;
    this.nameFieldValid = true;
    this.emailFieldValid = true;
    this.usernameFieldValid = true;
    this.passwordFieldValid = true;
    this.validMsgsEnvironment = environment.validationMessages;
    if ( this.polygonGeoJSON ) {
      this.polygonGeoJSON = JSON.stringify(this.polygonGeoJSON);
    }
  }

  afterModelLoad(): void {
    this.cd.detectChanges();
  }

  changeOrganizationImage(e: any) {
    this.image = e.target.files[0];
  }

  /**
   * close modal
   */
  closeModal() {
    this.bsModalRef.hide();
    this.reloadOrganizationsEvent.emit(null);
  }

  /**
   * send new risk data
   */
  onSubmit() {

    if ( ! this.validateForm() ) {
      return;
    }

    this.showProgressBar = true;
    this.cd.detectChanges(); // redraw modal

    const formData = new FormData();
    formData.append('name', this.name);
    formData.append('hasAssets', 'false');
    formData.append('organizationTypeId', this.organizationTypeId.toString());
    formData.append('email', this.email);
    formData.append('username', this.username);
    formData.append('password', this.password);

    if ( this.polygonGeoJSON ) {
      formData.append('polygonGeoJSON', this.polygonGeoJSON);
    }

    if ( this.image ) {
      formData.append('image', this.image);
    }
    if ( this.description ) {
      formData.append('description', this.description);
    }
    if ( this.address ) {
      formData.append('address', this.address);
    }
    if ( this.youtubePlayListId ) {
      formData.append('youtubePlayListId', this.youtubePlayListId);
    }
    if ( this.domain ) {
      formData.append('domain', this.domain);
    }

    this.httpService.saveFD(environment.endpoints.addOrganization, formData).subscribe( (res) => {
      this.showProgressBar = false;
      this.cd.detectChanges();
      // this.reloadRiskEvent.emit(res.data);
      this.toastr.success('La organización ha sido agregada satisfactoriamente');
      this.bsModalRef.hide();
    },
    (error) => {
      this.toastr.error('Error del servidor');
      this.showProgressBar = false;
      this.cd.detectChanges();
    });
  }

  validateForm() {

    this.nameFieldValid = true;
    this.emailFieldValid = true;
    this.usernameFieldValid = true;
    this.passwordFieldValid = true;

    let isValid = true;

    if ( !this.name) {
      this.nameFieldValid = false;
      isValid = false;
    }

    if ( !this.email) {
      this.emailFieldValid = false;
      isValid = false;
    }

    const emailRegexp = new RegExp(environment.regex.email);
    if ( ! emailRegexp.test(this.email) ) {
      this.emailFieldValid = false;
      isValid = false;
    }

    if ( !this.username) {
      this.usernameFieldValid = false;
      isValid = false;
    }

    const usernameRegexp = new RegExp(environment.regex.username);
    if ( ! usernameRegexp.test(this.username) ) {
      this.usernameFieldValid = false;
      isValid = false;
    }

    if ( !this.password) {
      this.passwordFieldValid = false;
      isValid = false;
    }

    const passRegexp = new RegExp(environment.regex.password);
    if ( ! passRegexp.test(this.password) ) {
      this.passwordFieldValid = false;
      isValid = false;
    }
    this.cd.detectChanges();
    return isValid;
  }
}
