import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { UserSchema } from 'src/app/_schemas/user-schema';
import { MenuSchema } from 'src/app/_schemas/menu-schema';
import { MenuOptionSchema } from 'src/app/_schemas/menu-option-schema';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';

declare var jQuery: any;

@Component({
  selector: 'app-left-side-menu',
  templateUrl: './left-side-menu.component.html',
  styleUrls: ['./left-side-menu.component.scss']
})
export class LeftSideMenuComponent implements OnInit, AfterViewInit  {

  principalMenu: MenuOptionSchema[];
  initialMenu = [
    new MenuOptionSchema(
    {
      name: 'Inicio',
      optionType: 'menu',
      url: '/index/home',
      icon: 'home',
      active: 'S',
      menu: '',
      groupText: 'principal_menu',
      childs: []
    }
  ),
  new MenuOptionSchema(
    {
      name: 'Información',
      optionType: 'menu',
      url: null,
      icon: 'info-circle',
      active: 'S',
      menu: '',
      groupText: 'principal_menu',
      childs: [
        {
          name: 'Sobre Riesgos 360',
          optionType: 'menu',
          url: '/index/info/about',
          icon: null,
          active: 'S',
          menu: '',
          groupText: 'principal_menu',
          childs: []
        },
        {
          name: 'Ayuda',
          optionType: 'menu',
          url: '/index/info/help',
          icon: null,
          active: 'S',
          menu: '',
          groupText: 'principal_menu',
          childs: []
        },
        {
          name: 'Contáctenos',
          optionType: 'menu',
          url: '/index/info/contact',
          icon: null,
          active: 'S',
          menu: '',
          groupText: 'principal_menu',
          childs: []
        },
        {
          name: 'Privacidad',
          optionType: 'menu',
          url: '/index/info/privacy',
          icon: null,
          active: 'S',
          menu: '',
          groupText: 'principal_menu',
          childs: []
        }
      ]
    }
  )];

  user: UserSchema;
  constructor(
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.userService.menu$.subscribe( (menu: MenuSchema) => {
      if ( menu && menu.principal_menu ) {
        this.principalMenu = menu.principal_menu;
      } else {
        this.principalMenu = this.initialMenu;
      }
    });

    this.userService.user$.subscribe( (user: UserSchema) => {
      this.user = user;
    });
  }

  ngAfterViewInit() {
    jQuery('#side-menu').metisMenu();
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  logout() {
    this.authenticationService.logout();
  }

  menuSubOptionClicked(icon: string) {
    if (jQuery('body').hasClass('mini-navbar')) {
      jQuery('#main-' + icon).trigger( 'click' );
    }
  }

}
