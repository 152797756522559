import { Component, OnInit } from '@angular/core';
import { GoogleProvider } from 'leaflet-geosearch';
import { ToastrService } from 'ngx-toastr';
import { UiService } from 'src/app/_services/ui.service';
import { UserService } from 'src/app/_services/user.service';
import { RiskSchema } from 'src/app/_schemas/risk-schema';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  showSearchAddress = false;
  searchAddressText = '';

  showSearchRisk = false;
  searchRiskText = '';

  showResults = false;
  resultRiskList: RiskSchema[] = [];

  constructor(
    public uiService: UiService,
    private toastr: ToastrService,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  selectOption(selectedOption) {

    this.showSearchAddress = false;
    this.showSearchRisk = false;
    switch (selectedOption) {
      case 'address':
        this.showSearchAddress = true;
        break;
      case 'risk':
        this.showSearchRisk = true;
        break;
    }

  }

  async searchAddress() {
    this.showResults = false;

    if ( this.searchAddressText ) {
      // setup
      const provider = new GoogleProvider({
        params: {
          key: 'AIzaSyDB4-7JWm2nkDv03B84SDey2-Wa_2bFR5A',
          components: 'country:CO'
        },
      });

      // search
      const resultsList  = await provider.search({ query: this.searchAddressText });

      if (resultsList.length > 0) {
        this.uiService.showAddressInMap(resultsList[0]);
      } else {
        this.toastr.warning('La busqueda no arrojo resultados');
      }
    }
  }

  cleanAddress() {
    this.uiService.showAddressInMap('clean');
    this.searchAddressText = '';
  }

  // read search text and show alerts
  searchRisk() {
    if ( this.searchRiskText ) {
      // search risks
      this.resultRiskList = [];
      this.userService.risks$.subscribe( (risks: RiskSchema[]) => {
        if ( risks.length > 0 ) {
          risks.map((risk, index) => {
            if ( risk.description.includes(this.searchRiskText)) {
              this.resultRiskList.push(risk);
            }

            if (index >= risks.length - 1) {
              if ( this.resultRiskList.length === 0 ) {
                this.toastr.warning('La busqueda no arrojo resultados');
              } else {
                this.showResults = true;
              }
            }
          });
        }
      });
    } else {
      this.showResults = false;
    }
  }

  cleanRisk() {
    this.searchRiskText = '';
    this.resultRiskList = [];
    this.showResults = false;
  }

}
