import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-child',
  templateUrl: './add-child.component.html',
  styleUrls: ['./add-child.component.scss']
})
export class AddChildComponent implements OnInit {
  @Output() 
  result = new EventEmitter<any>();
  childForm: FormGroup;
  child: any;
  showSaveButton = true;
  emailDomain: any;
  email: any = "";

  constructor(private formBuilder: FormBuilder,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.initChildForm();
    if (this.child) {
      this.childForm.controls.fullName.setValue(this.child.fullName);
      this.childForm.controls.email.setValue(this.child.email);
      this.childForm.controls.identificationNumber.setValue(this.child.identificationNumber);
      this.childForm.controls.domain.setValue(this.emailDomain);
      this.email = this.child.email.replace(this.emailDomain, "")
      setTimeout(() => this.childForm.disable());
      this.showSaveButton = false;
    }
  }

  initChildForm(){
    this.childForm = this.formBuilder.group({
      fullName: [''],
      email: [''],
      identificationNumber: [''],
      employeeId: [''],
      domain: [{value:'',  disabled: true}]
  });
  this.childForm.controls['domain'].setValue(this.emailDomain);
  }


  get f() { return this.childForm.controls; }

  addChild() {
    if(this.childForm.invalid || !this.email) {
      this.toastr.show("Ingrese toda la información");
      return;
    }
    const childUser = {
        employeeId: this.childForm.controls['employeeId'].value,
        fullName: this.childForm.controls['fullName'].value,
        email: this.childForm.controls['email'].value,
        identificationNumber: this.childForm.controls['identificationNumber'].value
      }
      this.modalService.onHide.emit(childUser);
      this.modalService._hideModal(1);
  }

  cancel() {
    this.bsModalRef.hide();
    this.modalService._hideModal(1);
  }
  
  get c() { return this.childForm.controls; }

  setFullEmail() {
    this.childForm.controls['email'].setValue(this.email + this.emailDomain);
  }
}
