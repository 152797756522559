import { MenuOptionSchema } from './menu-option-schema';

export class MenuSchema {
    'principal_header': MenuOptionSchema[];
    'principal_menu': MenuOptionSchema[];
    'principal_floating': MenuOptionSchema[];

    constructor(data?: any) {
        if (data !== undefined) {
             this.principal_header = data.principal_header !== undefined ? data.principal_header : null;
             this.principal_menu = data.principal_menu !== undefined ? data.principal_menu : null;
             this.principal_floating = data.principal_floating !== undefined ? data.principal_floating : null;
        }
    }

    static fromJS(data: any): MenuSchema {
        return new MenuSchema(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data.principal_header = this.principal_header !== undefined ? this.principal_header : null;
        data.principal_menu = this.principal_menu !== undefined ? this.principal_menu : null;
        data.principal_floating = this.principal_floating !== undefined ? this.principal_floating : null;
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new MenuSchema(JSON.parse(json));
    }

}
