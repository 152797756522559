import { Component, OnInit } from '@angular/core';
import { SelfDiagnostic } from 'src/app/_schemas/employee-schema';
import { HttpService } from 'src/app/_services/http.service';
import { BsModalRef } from 'ngx-bootstrap';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-modal-self-diagnostic',
  templateUrl: './modal-self-diagnostic.component.html',
  styleUrls: ['./modal-self-diagnostic.component.scss']
})
export class ModalSelfDiagnosticComponent implements OnInit {

  form: FormGroup;
  selfDiagnosticId: any;
  selfDiagnostic: SelfDiagnostic = <SelfDiagnostic>{};
  symptoms: any[];
  cases: any[];
  symptomsOptions = [];
  casesOptions = [];
  showLoading = true;

  constructor(private httpService: HttpService,
    private bsModalRef: BsModalRef,
    private formBuilder: FormBuilder) {

      this.form = this.formBuilder.group({
        symptomsOptions: new FormArray([]),
        casesOptions: new FormArray([]),
      });
  }



  ngOnInit() {
    this.getInitDateSelfDiagnostic();
  }

  getInitDateSelfDiagnostic() {
    this.httpService.get(environment.endpoints.initDataSelfDiagnostic).subscribe(res => {
      this.selfDiagnostic = res['data'];
      this.symptoms = this.selfDiagnostic.symptoms;
      this.cases = this.selfDiagnostic.possibleContagionCases;
      this.getAnswersSelfDiagnostic();
    });

  }

  getAnswersSelfDiagnostic() {
    this.httpService.get(environment.endpoints.getSelfDiagnosticById + this.selfDiagnosticId).subscribe(res => {
      this.selfDiagnostic = res['data'];
      this.addSymptomsCheckboxes();
      this.addCasesCheckboxes();
      this.showLoading = false;
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  private addSymptomsCheckboxes() {
    // mapear el array a {id, name} objects para que se pueda mostrar como checkbox
    this.symptoms.forEach(element => {
      const obj = {id: element.symptomId, name: element.name};
      this.symptomsOptions.push(obj);
    });

    this.symptomsOptions.map((o, i) => {
      let checked = false;
      if (this.selfDiagnostic.symptoms) {
        this.selfDiagnostic.symptoms.forEach(element => {
            if (o.id === element.symptomId) {
              checked = true;
          }
        });
      }
      const control = new FormControl({value: checked, disabled: true});
      (this.form.controls.symptomsOptions as FormArray).push(control);
    });
  }

  
  private addCasesCheckboxes() {
    // mapear el array a {id, name} objects para que se pueda mostrar como checkbox
    this.cases.forEach(element => {
      const obj = {id: element.possibleContagionCasesId, name: element.name};
      this.casesOptions.push(obj);
    });

    this.casesOptions.map((o, i) => {
      let checked = false;
      if (this.selfDiagnostic.possibleContagionCases) {
        this.selfDiagnostic.possibleContagionCases.forEach(element => {
            if (o.id === element.possibleContagionCasesId) {
              checked = true;
          }
        });
      }
      const control = new FormControl({value: checked, disabled: true});
      (this.form.controls.casesOptions as FormArray).push(control);
    });
  }
}
