import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UiService } from 'src/app/_services/ui.service';
import { RiskSchema } from 'src/app/_schemas/risk-schema';
import { HttpService } from 'src/app/_services/http.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { OrganizationSchema } from 'src/app/_schemas/organization-schema';
import { DomSanitizer } from '@angular/platform-browser';
import { FileService } from 'src/app/_services/file.service';
import { ConfirmComponent } from 'src/app/_modules/share/_components/confirm/confirm.component';
import { ToastrService } from 'ngx-toastr';
import { AddRiskAlertComponent } from '../add-risk-alert/add-risk-alert.component';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { Router } from '@angular/router';
import { AddRiskRecommendationComponent } from '../add-risk-recommendation/add-risk-recommendation.component';

declare var jQuery: any;

@Component({
  selector: 'app-risk-details',
  templateUrl: './risk-details.component.html',
  styleUrls: ['./risk-details.component.scss']
})
export class RiskDetailsComponent implements OnInit, OnDestroy {


  riskSubscribe: Subscription;
  organizationSubscribe: Subscription;
  roleSubscribe: Subscription;
  subscriptions: Subscription[] = [];

  riskId: number;
  risk: RiskSchema;

  organization: OrganizationSchema;
  role: string;

  showModal: boolean;
  dataLoaded: boolean;
  showProgressBar: boolean;

  // modal for templates
  bsModalRef: BsModalRef;

  // preview report
  previewFileURL;
  reportFileLoadedCount: number;

  // carousel galeria
  selectedFileIndex: number;
  selectedImage: any;

  // tranfer entities
  entityTransferId: any;

  constructor(
    private userService: UserService,
    private modalService: BsModalService,
    public mainBsModalRef: BsModalRef,
    public uiService: UiService, // used in html template
    private httpService: HttpService,
    private sanitizer: DomSanitizer,
    public fileService: FileService,
    private toastr: ToastrService,
  ) {
    this.modalService.onHide.subscribe((reason: string) => {
      this.showModal = true;
    });
   }

  ngOnInit() {

    this.showModal = true;
    this.dataLoaded = false;
    this.showProgressBar = false;
    this.selectedFileIndex = 0;

    this.riskSubscribe = this.userService.selectedRisk$.subscribe( risk => {
        if ( risk) {
          this.dataLoaded = true;
          this.risk = risk;
        } else {
          this.httpService.get(environment.endpoints.risk + this.riskId)
          .subscribe( (riskRes) => {
                this.risk = riskRes;
                this.dataLoaded = true;
          });
        }
    });

    this.organizationSubscribe = this.userService.organization$.subscribe( organization => {
      if ( organization ) {
        this.organization = organization;
      }
    });

    this.roleSubscribe = this.userService.role$.subscribe( role => {
      if ( role ) {
        this.role = role;
      }
    });
  }

  ngOnDestroy(): void {
    if ( this.riskSubscribe ) {
      this.riskSubscribe.unsubscribe();
    }
    if ( this.organizationSubscribe ) {
      this.organizationSubscribe.unsubscribe();
    }
    if ( this.roleSubscribe ) {
      this.roleSubscribe.unsubscribe();
    }
  }

  /**
   * download Jasper report
   */
  downloadReportFile(fileType: string) {
    let url = environment.endpoints.downloadReport
    + '?access_token=' + localStorage.getItem('accessToken')
    + '&backend_url=' + environment.endpoints.BACK_END_URL
    + '&frontend_url=' + environment.endpoints.FRONT_END_URL
    + '&reportName=' + 'RisksReport'
    + '&organizacion_id=' + this.organization.organizationId.toString()
    + '&riesgo_id_list=' + this.riskId + ',';

    switch (fileType) {
      case 'pdf':
        url += '&outputType=pdf';
        break;
      case 'xlsx':
        url += '&outputType=xlsx&showTitle=false&showPageFooter=false';
        break;
    }

    // get blob file
    this.httpService.getBlob( url, null).subscribe( (data: Blob) => {
      this.fileService.downloadFile(data, 'Riesgo_' + this.riskId + '_' + this.risk.description + '.' + fileType);
    });
  }

  /* preview Jasper report*/
  previewFile(template: TemplateRef<any>): void {
    const url = environment.endpoints.downloadReport
    + '?access_token=' + localStorage.getItem('accessToken')
    + '&backend_url=' + environment.endpoints.BACK_END_URL
    + '&frontend_url=' + environment.endpoints.FRONT_END_URL
    + '&reportName=' + 'RisksReport'
    + '&organizacion_id=' + this.organization.organizationId.toString()
    + '&riesgo_id_list=' + this.riskId + ',&outputType=pdf#toolbar=0';

    this.reportFileLoadedCount = 0;
    jQuery('#progressPreviewPdf').css('display', 'block');

    this.previewFileURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  reportFileLoaded() {
    this.reportFileLoadedCount ++;

    if ( this.reportFileLoadedCount === 2 ) {
      jQuery('#progressPreviewPdf').css('display', 'none');
    }
  }

  /**
   * carousel button event
   */
  changeCarouselItem(left: boolean): void {
    let totalFiles = 0;

    if (this.risk.fileList && this.risk.fileList.length > 0) {
      totalFiles = this.risk.fileList.length;
    }

    if (left) {
      this.selectedFileIndex = (((this.selectedFileIndex - 1 + totalFiles)) % totalFiles);
    } else {
      this.selectedFileIndex = ((this.selectedFileIndex + 1) % totalFiles);
    }
  }

  /**
   * show full screen image
   */
  imageFullScreen(template: TemplateRef<any>, file: any): void {
    this.selectedImage = file;
    jQuery('#progressFullScreen').css('display', 'block');
    this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  fullScreenLoaded() {
    jQuery('#progressFullScreen').css('display', 'none');
  }

  validateRisk() {

    const context = this;
    this.showModal = false;
    const initialState = {
      modalDataInput: {
        message: 'Esta acción no puede ser revertida, ¿Está seguro de aprobar este riesgo?',
        okBtnText: 'Sí',
        cancelBtnText: 'No',
      },
    };

    this.bsModalRef = this.modalService.show(ConfirmComponent, {
        class: 'modal-sm', initialState,
    });
    this.bsModalRef.content.returnModalResponseEvent.subscribe(
      (response) => {
        context.showModal = true;
        if (response === 'ok') {
          context.showProgressBar = true;
          const formData = new FormData();
          formData.append('riskId', context.riskId.toString());
          formData.append('valid', 'true');

          context.httpService.saveFD(environment.endpoints.riskValidate, formData).subscribe( (res) => {
              context.risk = res.data;
              context.toastr.success('Riesgo validado');
              context.showProgressBar = false;
              this.userService.updateRisks(context.risk);
          },
          error => {
            context.toastr.error('Error del servidor');
            context.showProgressBar = false;
          });
        }
      }
    );
  }

  invalidateRisk(template: TemplateRef<any>) {
    this.showModal = false;
    this.bsModalRef = this.modalService.show(template, {
      class: 'modal-lg'
    });
  }

  invalidateRiskCancel() {
    this.showModal = true;
    this.risk.reasonValidation = '';
    this.bsModalRef.hide();
  }

  invalidateRiskSave() {
    if ( !this.risk.reasonValidation ) {
      this.toastr.warning('Escriba la razon para invalidar este riesgo');
      return;
    }
    this.bsModalRef.hide();
    this.showProgressBar = true;
    this.showModal = true;

    const formData: FormData = new FormData();
    formData.append('riskId', this.riskId.toString());
    formData.append('valid', 'false');
    formData.append('reason', this.risk.reasonValidation);

    this.httpService.saveFD(environment.endpoints.riskValidate, formData).subscribe( (res) => {
      this.risk = res.data;
      this.toastr.success('Riesgo actualizado');
      this.showProgressBar = false;
      this.userService.updateRisks(this.risk);
    },
    error => {
      this.toastr.error('Error del servidor');
      this.showProgressBar = false;
    });
  }

  transferRisk(template: TemplateRef<any>) {
    this.showModal = false;
    this.entityTransferId = null;
    this.bsModalRef = this.modalService.show(template, {
      class: 'modal-sm'
    });
  }

  transferRiskSave() {
    this.bsModalRef.hide();
    this.showProgressBar = true;
    this.showModal = true;
    const formData = new FormData();
    formData.append('riskId', this.riskId.toString());
    formData.append('entityTransferId', this.entityTransferId);
    formData.append('organizationId', this.organization.organizationId.toString());

    this.httpService.saveFD(environment.endpoints.riskTransfer, formData).subscribe( (res) => {
      this.risk = res.data;
      this.toastr.success('Riesgo trasladado');
      this.showProgressBar = false;
      this.userService.updateRisks(this.risk);
    },
    (error) => {
      this.toastr.error('Error del servidor');
      this.showProgressBar = false;
    });
  }

  /**
   * open add alert modal
   */
  addAlert(alertLevel?: string) {

    const initialState = {
        riskId: this.riskId,
        alertLevel: alertLevel === undefined ? this.risk.riskAlertList[this.risk.riskAlertList.length - 1].type : alertLevel
    };
    this.bsModalRef = this.modalService.show(AddRiskAlertComponent, {
        class: 'modal-lg', initialState,
    });

    this.showModal = false;
    this.bsModalRef.content.reloadRiskEvent.subscribe(
      (riskAlert) => {
        this.showModal = true;
        this.risk.riskAlertList.push(riskAlert);
        this.userService.updateRisks(this.risk);
      }
    );
  }

  /**
   * open add Recommendation modal
   */
  addRecommendation() {

    const initialState = {
        riskId: this.riskId,
    };
    this.bsModalRef = this.modalService.show(AddRiskRecommendationComponent, {
        class: 'modal-lg', initialState,
    });

    this.showModal = false;
    this.bsModalRef.content.reloadRiskEvent.subscribe(
      (riskRecommendation) => {
        this.showModal = true;
        this.risk.riskRecommendationList.push(riskRecommendation);
        this.userService.updateRisks(this.risk);
      }
    );
  }


}
