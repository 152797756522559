import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET } from '@angular/router';

import { filter } from 'rxjs/operators';

export interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}

/**
 * Breadcrumb component fork from: http://brianflove.com/2016/10/23/angular2-breadcrumb-using-router/
 */
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: IBreadcrumb[];
  mainTitle: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.breadcrumbs = [];
    this.mainTitle = 'Riesgos 360';
  }

  ngOnInit() {
    const initialRoot: ActivatedRoute = this.activatedRoute.root;
    this.breadcrumbs = this.getBreadcrumbs(initialRoot);

    // subscribe to the NavigationEnd event
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
        // set breadcrumbs
      const root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
    });
  }

  /**
   * Returns array of IBreadcrumb objects that represent the breadcrumb
   *
   * @method getBreadcrumbs
   * @param ActivateRoute route
   * @param string currentUrl
   * @param IBreadcrumb[] breadcrumbs
   */
  private getBreadcrumbs(route: ActivatedRoute, currentUrl: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'title';

    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, currentUrl, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      currentUrl += `/${routeURL}`;

      // add breadcrumb
      const breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        url: currentUrl
      };
      breadcrumbs.push(breadcrumb);
      this.mainTitle = child.snapshot.data[ROUTE_DATA_BREADCRUMB];

      // recursive
      return this.getBreadcrumbs(child, currentUrl, breadcrumbs);
    }
  }

  /**
   * back button function
   */
  backButton() {
    history.back();
  }

}
