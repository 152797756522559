import { Component, AfterViewInit, OnInit } from '@angular/core';
import { correctHeight, detectBody } from '../assets/libs/app.helpers';
import { TitleService } from './_services/title.service';
declare var jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, AfterViewInit {

  loading = true;

  constructor(
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.titleService.init();
  }

  ngAfterViewInit() {
    // Run correctHeight function on load and resize window event
    jQuery(window).bind('load resize', () => {
      correctHeight();
      detectBody();
    });

    // Correct height of wrapper after metisMenu animation.
    jQuery('.metismenu a').click(() => {
      setTimeout(() => {
        correctHeight();
      }, 300);
    });

  }
}
