
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebook, faWhatsapp, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLink, faPrint } from '@fortawesome/free-solid-svg-icons';


import { registerLocaleData } from '@angular/common';
import localeEsCO from '@angular/common/locales/es-CO';
registerLocaleData(localeEsCO);

import { AppRoutingModule } from './app-routing.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FileUploadModule } from 'ng2-file-upload';

import { AppComponent } from './app.component';

import { AuthenticationService } from './_services/authentication/authentication.service';
import { HttpService } from './_services/http.service';
import { TokenStorageService } from './_services/authentication/token-storage.service';
import { AuthGuardService } from './_services/authentication/auth-guard.service';
// external modules and plug-ins
import { ToastrModule  } from 'ngx-toastr';
import { UserService } from './_services/user.service';
import { ModalModule, CarouselModule, TabsModule } from 'ngx-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShareButtonModule  } from '@ngx-share/button';

import { TableModule } from 'primeng/table';

/*******************/
/* GLOBAL SERVICES */
/*******************/
import { TitleService } from './_services/title.service';
import { GoogleMapModalComponent } from './_modules/index/_modules/home/_components/map/_components/google-map-modal/google-map-modal.component';
import { RiskDetailsComponent } from './_modules/index/_modules/home/_components/map/_components/risk/risk-details/risk-details.component';
import { ConfirmComponent } from './_modules/share/_components/confirm/confirm.component';
import { AddRiskAlertComponent } from './_modules/index/_modules/home/_components/map/_components/risk/add-risk-alert/add-risk-alert.component';
import { AddRiskRecommendationComponent } from './_modules/index/_modules/home/_components/map/_components/risk/add-risk-recommendation/add-risk-recommendation.component';
import { AddRiskComponent } from './_modules/index/_modules/home/_components/map/_components/risk/add-risk/add-risk.component';
import { AddOrganizationComponent } from './_modules/index/_modules/home/_components/map/_components/organization/add-organization/add-organization.component';
import { AlertComponent } from './_modules/share/_components/alert/alert.component';
import { OrganizationDetailsComponent } from './_modules/index/_modules/home/_components/map/_components/organization/organization-details/organization-details.component';
import { AddOrganizationAreaComponent } from './_modules/index/_modules/home/_components/map/_components/organization/add-organization-area/add-organization-area.component';
import { OrganizationAreaDetailsComponent } from './_modules/index/_modules/home/_components/map/_components/organization/organization-area-details/organization-area-details.component';
import { IotService } from './_services/iot.service';

import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FirebasePushNotificationService } from './_services/firebase-push-notification.service';
import { ShareModule } from './_modules/share/share.module';
import { RequestInterceptorService } from './_services/request-interceptor.service';
import { NgxPowerBiService } from 'ngx-powerbi';
import { AddChildComponent } from './_modules/employees/_components/employees-survey-familiar/_modals/add-child/add-child.component';
import { PassportComponent } from './_modules/employees/_components/employees-header/modals/passport/passport.component';

@NgModule({
  declarations: [
    AppComponent,
    GoogleMapModalComponent,
    RiskDetailsComponent,
    ConfirmComponent,
    AlertComponent,
    AddRiskAlertComponent,
    AddRiskRecommendationComponent,
    AddRiskComponent,
    AddOrganizationComponent,
    OrganizationDetailsComponent,
    AddOrganizationAreaComponent,
    OrganizationAreaDetailsComponent,
    AddChildComponent,
    PassportComponent
  ],
  entryComponents: [
    GoogleMapModalComponent,
    RiskDetailsComponent,
    ConfirmComponent,
    AlertComponent,
    AddRiskAlertComponent,
    AddRiskRecommendationComponent,
    AddRiskComponent,
    AddOrganizationComponent,
    OrganizationDetailsComponent,
    AddOrganizationAreaComponent,
    OrganizationAreaDetailsComponent,
    AddChildComponent,
    PassportComponent
  ],
  exports: [
    GoogleMapModalComponent,
    RiskDetailsComponent,
    ConfirmComponent,
    AlertComponent,
    AddRiskAlertComponent,
    AddRiskRecommendationComponent,
    AddRiskComponent,
    AddOrganizationComponent,
    OrganizationDetailsComponent,
    AddOrganizationAreaComponent,
    OrganizationAreaDetailsComponent,
    
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    // plug-ins
    ToastrModule.forRoot(),
    FileUploadModule,

    // ngx-bootstrap Modules
    ModalModule.forRoot(),
    CarouselModule,
    TabsModule,
    TableModule,

    // share buttons
    ShareButtonModule,

    // primeng
    ProgressBarModule,

    TabsModule.forRoot(),

    // font awesome icons
    FontAwesomeModule,

    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyAylzD7pEWuNL83okUvYBcS3CLEucHr2GI',
      authDomain: 'regal-campaign-203519.firebaseapp.com',
      databaseURL: 'https://regal-campaign-203519.firebaseio.com',
      projectId: 'regal-campaign-203519',
      storageBucket: 'regal-campaign-203519.appspot.com',
      messagingSenderId: '1008881354664',
      appId: '1:1008881354664:web:40871a707732c466a7ff9d'
    }),
    AngularFireMessagingModule,
    ShareModule
  ],
  providers: [
    AuthenticationService,
    HttpService,
    TokenStorageService,
    UserService,
    IotService,
    TitleService,
    BsModalService,
    AuthGuardService,
    FirebasePushNotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
    NgxPowerBiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
     library.addIcons(faFacebook, faWhatsapp, faTwitter, faEnvelope, faLink, faPrint);
  }
}
