import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { OrganizationSchema } from 'src/app/_schemas/organization-schema';
import { FileUploader } from 'ng2-file-upload';
import { BsModalRef } from 'ngx-bootstrap';
import { HttpService } from 'src/app/_services/http.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-organization-area-details',
  templateUrl: './organization-area-details.component.html',
  styleUrls: ['./organization-area-details.component.scss']
})
export class OrganizationAreaDetailsComponent implements OnInit {
  @Output() reloadOrganizationEvent = new EventEmitter<OrganizationSchema>();

  organizationId: number;
  organizationAreaId: number;
  name: string;
  description: string;
  userResponsable: string;
  emailUserResponsable: string;

  showProgressBar: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    private cd: ChangeDetectorRef,
    private httpService: HttpService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.showProgressBar = false;
  }

  afterModelLoad(): void {
    this.cd.detectChanges();
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  onSubmit() {
    this.showProgressBar = true;
    this.cd.detectChanges();

    const formData = new FormData();
    formData.append('organizationId', this.organizationId.toString());
    formData.append('organizationAreaId', this.organizationAreaId.toString());
    formData.append('name', this.name);
    if ( this.description ) {
      formData.append('description', this.description);
    }
    if ( this.userResponsable ) {
      formData.append('userResponsable', this.userResponsable);
    }
    if ( this.emailUserResponsable ) {
      formData.append('emailUserResponsable', this.emailUserResponsable);
    }

    this.httpService.saveFD(environment.endpoints.updateOrganizationArea, formData).subscribe( (res) => {
      this.toastr.success('Area actualizada correctamente');
      this.reloadOrganizationEvent.emit(res.data);
      this.showProgressBar = false;
      this.cd.detectChanges();
      this.bsModalRef.hide();
    },
    (error) => {
      this.toastr.error('Error del servidor');
      this.showProgressBar = false;
      this.cd.detectChanges();
    });

  }

}
