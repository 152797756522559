import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpParams } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication/authentication.service';
import { TokenStorageService } from './authentication/token-storage.service';

/**
 * general REST service
 */
@Injectable()
export class HttpService {
    private accessToken: string;

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private tokenStorage: TokenStorageService,
    ) {
        authenticationService.getAccessToken().subscribe(token => {
          authenticationService.tokenSubject.next(token);
        });
        authenticationService.token$.subscribe(token => {
            this.accessToken = token;
        });
    }

    /**
     * get JSON object Without Token
     */
    public getWithoutToken = (url: string, parameters?: HttpParams): Observable<any> => {
        return this.http.get(url, {
          params: parameters,
        }).pipe(
            map( res => res ),
            catchError(this.handleError),
        );
      }

    /**
     * get JSON object
     */
    public get = (url: string, parameters?: HttpParams): Observable<any> => {
      return this.http.get(url, {
        headers: {
          Authorization: 'Bearer ' + this.accessToken
        },
        params: parameters,
      }).pipe(
          map( res => res ),
          catchError(this.handleError),
      );
    }
        /**
     * get JSON object
     */
    public getData = (url: string, parameters?: HttpParams): Observable<any> => {
      return this.http.get(url, {
        headers: {
         'Content-Type': 'application/json; charset=UTF-8'
        },
        params: parameters,
      }).pipe(
          map( res => res ),
          catchError(this.handleError),
      );
    }


    /**
     * get Blob
     */
    public getBlob = (url: string, parameters: HttpParams): Observable<object> => {
        return this.http.get(url, {
            params: parameters,
            responseType: 'blob',
        }).pipe(
            map( res => res ),
            catchError(this.handleError),
        );
    }

    /**
     * get file (download)
     */
    public getFile = (Object: object, url: string, parameters: HttpParams): Observable<object> => {
        return this.http.get(url, {
            params: parameters,
            headers: {
              Authorization: 'Bearer ' + this.accessToken
            },
            responseType: 'blob',
        })
        .pipe(
            // map( res => res.json() ),
            map( res => res ),
            catchError(this.handleError),
        );
    }

    /**
     * save JSON object
     */
    public save = (url: string, item: object): Observable<object> => {
        return this.http.post(url, item, {
            headers: {
              Authorization: 'Bearer ' + this.accessToken
            },
        })
        .pipe(
            map( res => res ),
            catchError(this.handleError),
        );
    }

    /**
     * save JSON object without token
     */
    public saveWithoutToken = (url: string, item: object): Observable<object> => {
        return this.http.post(url, item)
        .pipe(
            map( res => res ),
            catchError(this.handleError),
        );
    }

    /**
     * save JSON object (with GET params)
     */
    public saveWithParams = (url: string, item: object, parameters: HttpParams): Observable<object> => {
        return this.http.post(url, item, {
            params: parameters,
            headers: {
              Authorization: 'Bearer ' + this.accessToken
            },
        })
        .pipe(
            map( res => res ),
            catchError(this.handleError),
        );
    }

    /**
     * Form-Data request
     */
    public saveFDWithoutToken = (url: string, item: object): Observable<any> => {
        return this.http.post(url, item)
        .pipe(
            // map( res => res.json() ),
            map( res => res ),
            catchError(this.handleError),
        );
    }

    /**
     * Form-Data request
     */
    public saveFD = (url: string, item: object): Observable<any> => {
        return this.http.post(url, item, {
            headers: {
              Authorization: 'Bearer ' + this.accessToken
            },
        })
        .pipe(
            // map( res => res.json() ),
            map( res => res ),
            catchError(this.handleError),
        );
    }

    public delete = (url: string, id: number): Observable<any> => {
        return this.http.delete(url + id, {
            headers: {
              Authorization: 'Bearer ' + this.accessToken
            },
        })
        .pipe(
            // map( res => res.json() ),
            map( res => res ),
            catchError(this.handleError),
        );
    }

    public handleError = (errorRes: HttpErrorResponse) => {
        return observableThrowError(errorRes || 'Server error');
    }
}
