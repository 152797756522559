import { SafeResourceUrl } from '@angular/platform-browser';

export class YoutubeRequestSchema {
    kind: string;
    etag: string;
    pageInfo: {
      totalResults: number,
      resultsPerPage: number,
    };
    items: {
      id: string,
      kind: string,
      etag: string,
      snippet: {
        publishedAt: Date,
        channelId: string,
        title: string,
        description: string,
        channelType: string,
        playlistId: string,
        position: number,
        resourceId: {
          kind: string,
          videoId: string,
        },
        thumbnails: {
          default: {
            url: string,
            width: number,
            height: number,
          },
          medium: {
            url: string,
            width: number,
            height: number,
          },
          high: {
            url: string,
            width: number,
            height: number,
          },
          standard: {
            url: string,
            width: number,
            height: number,
          },
        },
        sanitizedUrl: SafeResourceUrl, // generated object
      },
    }[];
  }