import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/_services/http.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-passport',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.scss']
})
export class PassportComponent implements OnInit, OnDestroy {

  currentDate: any;
  employee: any;
  passportColor: string;
  subscriptions: Subscription;
  isLoadedImage: boolean;
  imageSrc: string;

  constructor(
    private userService: UserService,
    private httpService: HttpService,) {

    this.subscriptions = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.userService.employee$.subscribe(employee => {
        if (employee) {
          this.employee = employee;
          this.currentDate = new Date();
          this.imageSrc = environment.endpoints.get_employee_qr_wellneess.replace('{employee_id}', this.employee.employeeId.toString()) ;
          if(this.employee.childs) {
            
            for (let child of this.employee.childs) {
              const url = environment.endpoints.get_qr_details.replace('{employeeId}', child.employeeId.toString());
              this.httpService.getWithoutToken( url).subscribe(
                (response) => {
                  if ( response.status === 'success' ) {
                    child.status = response.data.status;
                    child.passportColor = response.data.status;
                    child.imgQR = environment.endpoints.get_employee_qr_wellneess.replace('{employee_id}', child.employeeId.toString()) ;
                  }
                }
              );
            }
          }
          

        }
      })
    );
  }
  

  getClass() {
    if (this.isLoadedImage) {
      return this.passportColor;
    }
    return '';
  }

  onImageLoad($event) {
    this.isLoadedImage = true;
  }

}
