import {HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse,
    HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AuthenticationService } from './authentication/authentication.service';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

    isRefreshingToken: boolean;

    constructor(
        private injector: Injector
    ) {
        this.isRefreshingToken = false;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const authenticationService = this.injector.get(AuthenticationService);
        return next.handle(req)
        .pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        if (!this.isRefreshingToken) {
                            this.isRefreshingToken = true;
                            authenticationService.tokenSubject.next(null);
                            authenticationService.refreshToken().subscribe(
                                (tokens) => {
                                    // cargar pagina con el nuevo token
                                    location.reload(true);
                                    // Esto es para ejecutar nuevamente la peticion falliza
                                    // return next.handle(this.addToken(req, tokens.access_token));
                                },
                                error => {
                                    console.log('error-interceptor', error);
                                },
                                () => {
                                    console.log('finalize - refreshToken');
                                    this.isRefreshingToken = false;
                                }
                            );
                        }
                    }
                    return throwError(err);
                }
            }), finalize(() => {
            })
        );
    }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }});
    }

}
