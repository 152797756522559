import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { OrganizationSchema } from 'src/app/_schemas/organization-schema';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { FileUploader } from 'ng2-file-upload';
import { HttpService } from 'src/app/_services/http.service';
import { Category, RiskType, RiskSchema } from 'src/app/_schemas/risk-schema';

@Component({
  selector: 'app-add-risk',
  templateUrl: './add-risk.component.html',
  styleUrls: ['./add-risk.component.scss']
})
export class AddRiskComponent implements OnInit, OnDestroy {

  @Output() reloadRiskEvent = new EventEmitter<RiskSchema>();

  // input
  coordinates: any;

  // subscriptions
  organizationSubscribe: Subscription;

  organization: OrganizationSchema;
  categories: Category[];
  riskTypes: RiskType[];

  showProgressBar: boolean;

  // form fields
  riskTypeId: number;
  description: string;
  comments: string;
  municipalityCode: string;
  alertLevel: string;
  alertDescription: string;
  polygonGeoJSON: string;

  categoryId: number;

  // file uploader variables
  public uploader: FileUploader;
  public hasBaseDropZoneOver;
  uploaderResponse: string;

  constructor(
    public bsModalRef: BsModalRef,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private toastr: ToastrService,
    private httpService: HttpService
  ) {
    this.uploader = new FileUploader({
      url: environment.endpoints.BACK_END_URL,
      allowedMimeType: ['image/png', 'image/jpeg', 'audio/mp3', 'audio/mpeg', 'video/mp4', 'video/mpeg'],
      maxFileSize: 10 * 1024 * 1024,
      queueLimit: 5,
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise( (resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });

    this.hasBaseDropZoneOver = false;
    this.uploaderResponse = '';
    this.uploader.response.subscribe( res => this.uploaderResponse = res );
  }

  ngOnInit() {
    this.showProgressBar = false;

    this.organizationSubscribe = this.userService.organization$.subscribe( organization => {
      if ( organization ) {
        this.organization = organization;
      }
    });
    this.getTerritorialUnitData(this.coordinates.lat, this.coordinates.lng);
    this.loadCategories();
    if (this.polygonGeoJSON) {
      this.polygonGeoJSON = JSON.stringify(this.polygonGeoJSON);
    }
  }

  afterModelLoad(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {

    if ( this.organizationSubscribe ) {
      this.organizationSubscribe.unsubscribe();
    }
  }

  /**
   * close modal
   */
  closeModal() {
    this.bsModalRef.hide();
    this.reloadRiskEvent.emit(null);
  }

  /**
   * send new risk data
   */
  onSubmit() {

    this.showProgressBar = true;
    this.cd.detectChanges(); // redraw modal

    const formData = new FormData();
    formData.append('riskTypeId', this.riskTypeId.toString());
    formData.append('description', this.description);
    formData.append('comments', this.comments);
    formData.append('lat', this.coordinates.lat.toString());
    formData.append('lng', this.coordinates.lng.toString());
    formData.append('municipalityCode', '11001');
    formData.append('alertLevel', this.alertLevel);
    formData.append('alertDescription', this.alertDescription);
    formData.append('polygonGeoJSON', this.polygonGeoJSON);
    formData.append('deparment', '11');

    for (let i = 0; i < this.uploader.queue.length; i++) {
      const element = this.uploader.queue[i];
      formData.append('files', element._file);
    }

    if ( this.organization ) {
      formData.append('organizationId', this.organization.organizationId.toString());
    } else {
      this.categories.map(category => {
        if ( category.categoryId === +this.categoryId) {
          formData.append('organizationId', category.organizationId.toString());
          return;
        }
      });
    }
    this.httpService.saveFD(environment.endpoints.addRisk, formData).subscribe( (res) => {
      this.showProgressBar = false;
      this.cd.detectChanges();
      this.reloadRiskEvent.emit(res.data);
      this.toastr.success('Riesgo guardado correctamente');
      this.bsModalRef.hide();
    },
    (error) => {
      this.toastr.error('Error del servidor');
      this.showProgressBar = false;
      this.cd.detectChanges();
    });
  }

  /**
   * detect department and municipaly by coordinates
   * @param lat is the latitude
   * @param lng is the longitude
   */
  getTerritorialUnitData(lat: number, lng: number) {

    const context = this;
    const layer = '0';
    const params = 'service=WFS&version=1.0.0&request=GetFeature&typeName=DatosEspacialesShp%3ALimitesAdministrativos&bbox'
    + `=${lng},${lat},${lng + 0.00000000001},${lat + 0.00000000001}&`
    + `height=1&width=1&&srs=EPSG%3A3116&outputFormat=application%2Fjson`;


    // http://informariesgos.com:8080/geoserver/DatosEspacialesShp/wms?service=WMS&version=1.1.0&request=GetMap&layers=DatosEspacialesShp%3ALimites_Administrativos&bbox=-81.73562054499996%2C-4.227883515999963%2C-66.84721514099998%2C13.394727760000059&width=648&height=768&srs=EPSG%3A4326&styles=&format=application/openlayers

    // http://informariesgos.com:8080/geoserver/DatosEspacialesShp/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=DatosEspacialesShp%3ALimitesAdministrativos&bbox=-73.97468090057,11.292094755238466,-73.97468090056,11.292094755248465&height=1&width=1&srs=EPSG:4326


    // http://informariesgos.com:8080/geoserver/DatosEspacialesShp/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=DatosEspacialesShp%3ALimitesAdministrativos&bbox==11.292094755238466,-73.97468090057374,11.292094755248465,-73.97468090056374&height=1&width=1&srs=EPSG:4326&outputFormat=application%2Fjson


    // http://informariesgos.com:8080/geoserver/DatosEspacialesShp/wms?service=WMS&version=1.1.0&request=GetMap&layers=DatosEspacialesShp%3ALimites_Administrativos&bbox=-81.73562054499996%2C-4.227883515999963%2C-66.84721514099998%2C13.394727760000059&width=648&height=768&srs=EPSG%3A4326&styles=&format=application/openlayers

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        const xmlDoc = new DOMParser().parseFromString(this.response, 'text/xml');
        const res = xmlDoc.getElementsByTagName('FIELDS');
        console.log(this.response)
        if (res && res.item(0)) {
          context.municipalityCode = res.item(0).getAttribute('CódigoMunicipio');
          
        } else {
          context.toastr.warning('No se han encontrado datos de departamento y munipicio en estas coordenadas');
          context.municipalityCode = null;
          context.municipalityCode = "99773";
        }
      }
    };
    context.municipalityCode = "99773";
    console.log(environment.maps.geoserver_riesgos + params)
    //xhttp.setRequestHeader('Access-Control-Allow-Origin','*')
    xhttp.open('GET', environment.maps.geoserver_riesgos + params, true);
    xhttp.send();
  }

  loadCategories() {
    this.userService.role$.subscribe(role => {
      let url;
      switch (role) {
        case 'RL_ADMIN_ORG_RISK_ST':
          url = environment.endpoints.organizationCategories;
          break;
        case 'RL_USER_RISK_ST':
          url = environment.endpoints.allCategories;
          break;
      }
      this.httpService.get(url).subscribe((res) => {
        this.categories = res;
        this.cd.detectChanges();
      });
    });
  }
  /**
   * load risk types (by category and user)
   */
  loadRiskTypes() {
    this.categories.map(category => {
      if (category.categoryId.toString() === this.categoryId.toString()) {
        this.riskTypes = category.riskTypeList;
        this.cd.detectChanges();
      }
    });
  }

  /**
   * event when select alert type
   */
  selectAlertLevel(alertType: string) {
    if (alertType) {
      this.alertLevel = alertType;
      this.cd.detectChanges();
    }
  }

  // detect drag & drop mouse cursor
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
    this.cd.detectChanges();
  }

  // patches for redraw modal data
  onChange(e: Event) {
    this.cd.detectChanges();
  }
  clearQueue() {
    this.uploader.clearQueue();
    this.cd.detectChanges();
  }
  removeItem(item) {
    item.remove();
    this.cd.detectChanges();
  }
}
