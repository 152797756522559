import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { smoothlyMenu } from '../../../../../assets/libs/app.helpers';
import { UserSchema } from 'src/app/_schemas/user-schema';
import { MenuOptionSchema } from 'src/app/_schemas/menu-option-schema';
import { UserService } from 'src/app/_services/user.service';
import { MenuSchema } from 'src/app/_schemas/menu-schema';
import { UiService } from 'src/app/_services/ui.service';
import { OrganizationSchema } from 'src/app/_schemas/organization-schema';

declare var jQuery: any;

@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss']
})
export class TopNavBarComponent implements OnInit {

  @Output() showRightPanelEvent = new EventEmitter<string>();

  showRightSidebar = false;
  activeRigthSideBar = '';

  headerMenu: MenuOptionSchema[];
  initialMenu = [
    new MenuOptionSchema(
    {
      name: 'Videos',
      optionType: 'topnavbar',
      url: 'showRightPanel',
      icon: 'tasks',
      active: 'S',
      menu: '',
      groupText: 'principal_header',
      childs: []
    }
  )];

  user: UserSchema;
  organization: OrganizationSchema;

  constructor(
    private userService: UserService,
    private uiService: UiService
  ) { }

  ngOnInit() {

    this.userService.menu$.subscribe( (menu: MenuSchema) => {
      if ( menu && menu.principal_menu ) {
        this.headerMenu = menu.principal_header;
      } else {
        this.headerMenu = this.initialMenu;
      }
    });

    this.userService.user$.subscribe( (user: UserSchema) => {
      this.user = user;
    });

    this.uiService.topMenuSelected$.subscribe( topMenuSelected => {
      this.activeRigthSideBar = topMenuSelected;
    });

    this.userService.organization$.subscribe( organization => {
      if ( organization ) {
        this.organization = organization;
      }
    });

  }

  toggleNavigation(): void {
    if (!jQuery('body').hasClass('mini-navbar')) {
      $('.nav-main-item').each((i, element) => {
        if (!element.classList.contains('collapsed')) {
          element.click();
        }
      });
    }
    jQuery('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }

  showRightPanel(event) {
    this.showRightPanelEvent.emit(event);
  }

}
