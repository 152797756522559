import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UserService } from 'src/app/_services/user.service';
import * as L from 'leaflet';

@Component({
  selector: 'app-modal-map-employee',
  templateUrl: './modal-map-employee.component.html',
  styleUrls: ['./modal-map-employee.component.scss']
})
export class ModalMapEmployeeComponent implements OnInit {

  coordinates: any;

  constructor(private userService: UserService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,) { }

  ngOnInit() {

  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
