import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private key: string = 'AAAA6uYDx6g:APA91bF1hmFA3a2zNBOce8H6PRQ5Al5tQAJDmNFgyHf9w7lglz8EOMfSSSmMvOD68zkujfqF'
              + 'f1MAS7Lp19JhWD9bCzpvcIUZWBjY52OTKT9TrHxptiMKZq35miuayfOyGyqtC5sJs9EM';

  constructor() { }

  registerForPush = (topic: string, token: string) => {
    const subscriptionUrl = `https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`;

    // send subscription to firebase backend
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        console.log('subscribing topic', topic);
      }
    };
    xhttp.open('POST', subscriptionUrl, true);
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.setRequestHeader('Authorization', `key=${this.key}`);
    xhttp.send();
  }

  unRegisterForPush = (topic: string, token: string) => {
    const subscriptionUrl = `https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`;

    // send subscription to firebase backend
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        console.log('unSubscribing topic', topic);
      }
    };
    xhttp.open('DELETE', subscriptionUrl, true);
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.setRequestHeader('Authorization', `key=${this.key}`);
    xhttp.send();
  }

  sendNotification = (notification: any) => {
    // send message to firebase backend
    const url = 'https://fcm.googleapis.com/fcm/send';

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        // console.log('sending');
      }
    };
    xhttp.open('POST', url , true);
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.setRequestHeader('Authorization', `key=${this.key}`);
    xhttp.send(JSON.stringify(notification));
  }
}
