import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { environment } from 'src/environments/environment';
declare var jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class UiService {

  public static DT_LANG_ES: DataTables.LanguageSettings = {
    processing:     'Procesando...',
    lengthMenu:     'Mostrar _MENU_ registros',
    zeroRecords:    'No se encontraron resultados',
    emptyTable:     'Ningún dato disponible en esta tabla',
    info:           'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
    infoEmpty:      'Mostrando registros del 0 al 0 de un total de 0 registros',
    infoFiltered:   '(filtrado de un total de _MAX_ registros)',
    infoPostFix:    '',
    search:         'Buscar:',
    url:            '',
    // infoThousands:  ',',
    loadingRecords: 'Cargando...',
    paginate: {
        first:    'Primero',
        last:     'Último',
        next:     'Siguiente',
        previous: 'Anterior'
    },
    aria: {
        sortAscending:  ': Activar para ordenar la columna de manera ascendente',
        sortDescending: ': Activar para ordenar la columna de manera descendente'
    }
  };

  private address = null;
  private showAddress = new BehaviorSubject<any>(this.address);
  public showAddress$ = this.showAddress.asObservable();

  private showLayer = new BehaviorSubject<any>(null);
  public showLayer$ = this.showLayer.asObservable();

  private removeLayer = new BehaviorSubject<any>(null);
  public removeLayer$ = this.removeLayer.asObservable();

  private topMenuSelected: null;
  private topMenuSelectedSbj = new BehaviorSubject<any>(null);
  public topMenuSelected$ = this.topMenuSelectedSbj.asObservable();

  private loadMarkers = new BehaviorSubject<any>(null);
  public loadMarkers$ = this.loadMarkers.asObservable();
  
  public mapEmployee = 'employee';
  private mapEmployeeFilter = new BehaviorSubject<string>('employee');
  public mapEmployeeFilter$ = this.mapEmployeeFilter.asObservable();

  constructor() {
  }

  /**
   * Custom tile layer for show WMS metadata
   */
  BetterWMS = L.TileLayer.WMS.extend({

    onAdd(map) {
        // Triggered when the layer is added to a map.
        //   Register a click listener, then do all the upstream WMS things
        L.TileLayer.WMS.prototype.onAdd.call(this, map);
        map.on('click', this.getFeatureInfo, this);
    },

    onRemove(map) {
        // Triggered when the layer is removed from a map.
        //   Unregister a click listener, then do all the upstream WMS things
        L.TileLayer.WMS.prototype.onRemove.call(this, map);
        map.off('click', this.getFeatureInfo, this);
    },

    getFeatureInfo(evt) {
        // Make an AJAX request to the server and hope for the best
        const thisUrl = this.getFeatureInfoUrl(evt.latlng);
        const showResults: any = L.Util.bind(this.showGetFeatureInfo, this);
        // var wmsParams = this.wmsParams;

        jQuery.ajax({
            url: thisUrl,
            success(data, status, xhr) {
            // console.log(data);
            // var err = typeof data === 'string' ? null : data;
            // showResults(err, evt.latlng, data);
            showResults(null, evt.latlng, data);
            },
            error(xhr, status, error) {
            showResults(error);
            }
        });
    },

    getFeatureInfoUrl(latlng) {
        // Construct a GetFeatureInfo request URL given a point
        let point = this._map.latLngToContainerPoint(latlng, this._map.getZoom()),
        size = this._map.getSize(),
        params = {
            request: 'GetFeatureInfo',
            service: 'WMS',
            srs: 'EPSG:4326',
            styles: this.wmsParams.styles,
            transparent: this.wmsParams.transparent,
            version: this.wmsParams.version,
            format: this.wmsParams.format,
            bbox: this._map.getBounds().toBBoxString(),
            height: size.y,
            width: size.x,
            layers: this.wmsParams.layers,
            query_layers: this.wmsParams.layers,
            info_format: 'application/json',
        };

        params[params.version === '1.3.0' ? 'i' : 'x'] = point.x;
        params[params.version === '1.3.0' ? 'j' : 'y'] = point.y;

        return this._url + L.Util.getParamString(params, this._url, true);
    },

    showGetFeatureInfo(err, latlng, json) {
        // do nothing if there's an error
        if (err) { console.log(err); return; }
        // var popupText = jsonFeaturesToText(json);
        const xmlFeaturesToText = async (response: any)  => {
          let htmlText = '';
          if (response instanceof XMLDocument) {
            const data = response.getElementsByTagName('FIELDS');
            if (data && data.item(0)) {
  
              const locationCode = data.item(0).getAttribute('Identificadorunicodelalocalidad');
  
              await jQuery.ajax({
                url: environment.endpoints.count_biological_by_location.replace('{locationCode}', locationCode),
                success(dataCount, status, xhr) {
                  let tempText = '';
                  tempText += '<li><b>Identificador unico de la localidad:</b> '
                    + locationCode + '</li>';
                  tempText += '<li><b>Nombre de la localidad:</b> '
                    + data.item(0).getAttribute('Nombredelalocalidad') + '</li>';
                  tempText += '<li><b>Area de la localidad:</b> ' + data.item(0).getAttribute('Areadelalocalidad') + '</li>';
                  tempText += '<li><b>Número de casos confirmados por el laboratorio de riesgos biológicos:</b> ' 
                    + dataCount.data + '</li>';
  
                  htmlText += '<ul style="padding-left: 0;">' + tempText + '</ul>';
                },
                error(xhr, status, error) {}
              });
            }
            return htmlText;
          } else {
            if (response.features && response.features.length > 0) {
              let value;
              let tempText;
              response.features.map((tempFeature) => {
                tempText = '';
                Object.keys(tempFeature.properties).forEach((key) => {
                  value = tempFeature.properties[key];
                  tempText += '<li><b>' + key + ':</b> ' + value + '</li>';
                });
                htmlText += '<ul style="padding-left: 0;">' + tempText + '</ul>';
              });
            }
            return htmlText;
          }
        };

        xmlFeaturesToText(json).then(async (popupText) => {
          if (!popupText) {
            return;
          }
          // Otherwise show the content in a popup, or something.
          L.popup({ maxWidth: 300})
            .setLatLng(latlng)
            .setContent(await popupText)
            .openOn(this._map);
        });
    }
  });

  betterWms = (url, options) => {
    return new this.BetterWMS(url, options);
  }

  updateTopMenuSelected(topMenuSelected) {
    this.topMenuSelected = topMenuSelected;
    this.topMenuSelectedSbj.next( this.topMenuSelected );
  }

  showAddressInMap(address) {
    this.address = address;
    this.showAddress.next( this.address );
  }

  addLayerToMap(layer) {
    this.showLayer.next( layer );
  }

  removeLayerFromMap(layer) {
    this.removeLayer.next( layer );
  }

  getAlertColor(type: string) {
    let alertColor = '';
    switch (type) {
      case 'V':
        alertColor = '#00B073';
        break;
      case 'A':
        alertColor = '#FAC43E';
        break;
      case 'N':
        alertColor = '#F38409';
        break;
      case 'R':
          alertColor = '#F15D5E';
          break;
      case 'C':
          alertColor = '#676a6c';
          break;
      default:
        break;
    }

    return alertColor;
  }

  getAlertText(type: string) {
    let alertText = '';
    switch (type) {
      case 'V':
        alertText = 'Verde';
        break;
      case 'A':
        alertText = 'Amarilla';
        break;
      case 'N':
        alertText = 'Naranja';
        break;
      case 'R':
          alertText = 'Roja';
          break;
      case 'C':
        alertText = 'Cerrada';
        break;
      default:
        break;
    }
    return alertText;
  }

  getFuncionarioFilter() {
    return this.mapEmployeeFilter;
  }

  setFuncionarioFilter(code) {
    this.mapEmployee = code;
    this.mapEmployeeFilter.next( this.mapEmployee);
  }
}
