import { Component, OnInit, TemplateRef } from '@angular/core';
import { Chart } from 'chart.js';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/_services/http.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-events-charts',
  templateUrl: './events-charts.component.html',
  styleUrls: ['./events-charts.component.scss']
})
export class EventsChartsComponent implements OnInit {

  // departments select
  departments;
  selectedDepartament: string;

  // municipalities listBox
  municipalities;
  selectedMunicipality: string;

  // categories
  riskCategories;
  selectedRiskCategory: string;

  // riskTypes
  riskTypes;
  selectedRisksType: string;

  // checkbox
  nationalCheck: boolean;
  departmentCheck: boolean;
  categoriesCheck: boolean;

  // Progress bar
  showProgressBar: boolean;

  // chart
  chart: Chart = null; chart2: Chart = null; fullChart: Chart = null;
  canvas; canvas2; canvasFull;
  ctx; ctx2; ctxFull;
  dataChart; dataChart2; dataChartFull;

  bsModalRef: BsModalRef;
  modalTitle: string;
  subscriptions: Subscription[] = [];

  constructor(
    private httpService: HttpService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.nationalCheck = true;
    this.departmentCheck = false;
    this.categoriesCheck = true;
    this.showProgressBar = false;
    this.loadDepartments();
  }

  /**
   * load departments
   */
  loadDepartments() {
    this.httpService.get(environment.endpoints.departments).subscribe((res: any) => {
      // set department values
      this.departments = [{label: 'Seleccione un departamento', value: null}];
      res.forEach(department => {
          this.departments.push({label: department.name, value: department.locationId});
      });
    });
  }

  /**
   * load municipalities
   */
  loadMunicipalities() {

    this.selectedMunicipality = '';
    if ( ! this.selectedDepartament ) {
        this.municipalities = [];
        return;
    }
    const url = environment.endpoints.municipalities.replace('%departmentId%', this.selectedDepartament);
    this.httpService.get(url).subscribe((res: any) => {
      this.municipalities = [{label: 'Seleccione un municipio', value: null}];
      res.forEach(municipality => {
        this.municipalities.push({label: municipality.name, value: municipality.locationId});
      });
    });
  }

  loadRiskCategories() {
    this.httpService.get(environment.endpoints.organizationCategories).subscribe((res: any) => {
      this.riskCategories = [{label: 'Seleccione una categoría', value: null}];
      res.forEach(category => {
        this.riskCategories.push({label: category.description, value: category.categoryId});
      });
    });
  }

  loadRiskTypes() {

    this.selectedRisksType = '';
    if ( ! this.selectedRiskCategory ) {
        this.riskTypes = [];
        return;
    }
    this.httpService.get(environment.endpoints.riskType + this.selectedRiskCategory).subscribe((res: any) => {
      this.riskTypes = [{label: 'Seleccione un tipo de Riesgo', value: null}];
      res.forEach(type => {
          this.riskTypes.push({label: type.description, value: type.riskTypeId});
      });
    });
  }

  onChangeCategoriesCheck() {
    if ( ! this.categoriesCheck && ! this.riskCategories ) {
        this.loadRiskCategories();
    }
  }

  /**
   * See statisctic chart
   */
  seeChart() {

    let canShowChart = true;

    if ( ! this.nationalCheck ) {
        if ( this.selectedDepartament ) {
            if ( ! this.departmentCheck ) {
                if ( ! this.selectedMunicipality ) {
                    canShowChart = false;
                }
            } else {
                this.selectedMunicipality = '';
            }
        } else {
            canShowChart = false;
        }
    } else {
        this.selectedDepartament = '';
    }

    const fromYear = +(document.getElementById('fromYear') as HTMLInputElement).value;
    const untilYear = +(document.getElementById('untilYear') as HTMLInputElement).value;

    if ( ! fromYear || ! untilYear ) {
        canShowChart = false;
    }

    if ( ! this.categoriesCheck ) {
        if ( ! this.selectedRiskCategory || ! this.selectedRisksType ) {
        canShowChart = false;
        }
    } else {
        this.selectedRiskCategory = '';
        this.selectedRisksType = '';
    }

    if ( canShowChart ) {

      this.showProgressBar = true;
      let params = '';

      if ( this.selectedDepartament ) {
        params += '&departmentId=' + this.selectedDepartament;
      }
      if ( this.selectedMunicipality ) {
        params += '&municipalityId=' + this.selectedMunicipality;
      }
      if ( this.selectedRisksType ) {
        params += '&riskTypeId=' + this.selectedRisksType;
      }

      // chart 1 - incidences
      if ( this.chart ) {
        this.chart.destroy();
      }

      // chart 2- afectaciones
      if ( this.chart2 ) {
        this.chart2.destroy();
      }

      let url = environment.endpoints.chart.incidencesByYearRange.replace('%fromYear%', fromYear.toString());
      url = url.replace('%untilYear%', untilYear.toString());
      this.httpService.get(url + params).subscribe((res: any) => {

          // canvas
          this.canvas = document.getElementById('canvas');
          this.ctx = this.canvas.getContext('2d');
          this.dataChart = {
            type: 'bar',
            data: res.data.incidences,
            options: {
              responsive: true,
              maintainAspectRatio: false,
              title: {
                display: true,
                text: 'Incidencias',
              },
              legend: {
                display: true,
                position: 'bottom'
              },
              scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    }
                }]
              }
            }
          };
          this.showProgressBar = false;
          this.chart = new Chart(this.ctx, this.dataChart);

          // canvas 2
          this.canvas2 = document.getElementById('canvas2');
          this.ctx2 = this.canvas2.getContext('2d');
          this.dataChart2 = {
            type: 'doughnut',
            data: res.data.affectations,
            options: {
              responsive: true,
              maintainAspectRatio: false,
              title: {
                display: true,
                text: 'Afectaciones',
              },
              legend: {
                display: true,
                position: 'bottom'
              },
              scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    }
                }]
              },
              tooltips: {
                callbacks: {
                  label: (item, data) => {
                      return data.datasets[item.datasetIndex].label + ' ('
                              + data.labels[item.index] + ') : '
                              + data.datasets[item.datasetIndex].data[item.index];
                  }
                }
              }
            }
          };
          this.chart2 = new Chart(this.ctx2, this.dataChart2);
      });

    } else {
        this.toastr.warning('Por favor llene todos los filtros');
    }
  }

  showFullChart(template: TemplateRef<any>, chart: string ) {

    this.subscriptions.push(
      this.modalService.onShown.subscribe((reason: string) => {

        this.canvasFull = document.getElementById('chartFull');
        this.ctxFull = this.canvasFull.getContext('2d');
        let data;
        switch (chart) {
          case 'incidence':
            this.modalTitle = 'Incidencias';
            data = this.dataChart;
            break;
          case 'afectation':
            this.modalTitle = 'Afectaciones';
            data = this.dataChart2;
            break;
        }
        this.dataChartFull = data;
        this.dataChartFull.options.title = {
            display: false,
        };
        this.dataChartFull.options.legend = {
          display: true,
          position: 'left'
        };
        this.fullChart = new Chart(this.ctxFull, this.dataChartFull);
      })
    );

    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        this.unsubscribe();
      })
    );

    this.bsModalRef = this.modalService.show(template, {
      class: 'modal-lg'
    });

  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}
