// import { RiskFileSchema } from './risk-file-schema';
export class EmployeeHealthSurveySchema {
    // MANDATORY
    employeeId: number;
    fullName: string;
    identificationNumber: string;
    cellphoneNumber: string;
    email: string;
    verificationCode: string;
    healthState: string;
    highRiskDisease: string;
    presentedSymptomId: number;
    currentCondition: string;
    liveWith: string;
    familySymptomId: number;
    assessedBiological: string;
    transportationIds: any;
    hasIssueReturningWork: string;
    occupation: string;
    boss: string;
    organizationAreaId: number;
    taskId: number;
    executedAt: string;
    age: string;
    weight: string;
    height: string;
    liveWithIds: any;
    isASmoker: string;


    // OPTIONAL
    identificationTypeId: number;
    diseaseId: number;
    issueReturningWork: string;
    telecommutingHealthHabit: string;
    telecommutinWorkload: string;
    benefitInQuarantine: string;
    difficultyInQuarantine: string;
    observation: string;
    files: any[];

     constructor(data?: any) {
        if (data === undefined) {
            this.employeeId = null;
            this.fullName = null;
            this.identificationNumber = null;
            this.identificationTypeId = null;
            this.cellphoneNumber = null;
            this.email = null;
            this.verificationCode = null;
            this.healthState = null;
            this.highRiskDisease = null;
            this.diseaseId = null;
            this.presentedSymptomId = null;
            this.currentCondition = null;
            this.liveWith = null;
            this.familySymptomId = null;
            this.assessedBiological = null;
            this.transportationIds = null;
            this.liveWithIds = null;
            this.hasIssueReturningWork = null;
            this.occupation = null;
            this.boss = null;
            this.organizationAreaId = null;
            this.taskId = null;
            this.executedAt = null;
            this.issueReturningWork = null;
            this.telecommutingHealthHabit = null;
            this.telecommutinWorkload = null;
            this.benefitInQuarantine = null;
            this.difficultyInQuarantine = null;
            this.observation = null;
            this.files = null;
            this.weight = null;
            this.height = null;
            this.age = null;
            this.isASmoker = null;
        } else {
            this.employeeId = data.employeeId !== undefined ? data.employeeId : null;
            this.fullName = data.fullName !== undefined ? data.fullName : null;
            this.identificationNumber = data.identificationNumber !== undefined ? data.identificationNumber : null;
            this.identificationTypeId = data.identificationTypeId !== undefined ? data.identificationTypeId : null;
            this.cellphoneNumber = data.cellphoneNumber !== undefined ? data.cellphoneNumber : null;
            this.email = data.email !== undefined ? data.email : null;
            this.verificationCode = data.verificationCode !== undefined ? data.verificationCode : null;
            this.healthState = data.healthState !== undefined ? data.healthState : null;
            this.highRiskDisease = data.highRiskDisease !== undefined ? data.highRiskDisease : null;
            this.diseaseId = data.diseaseId !== undefined ? data.diseaseId : null;
            this.presentedSymptomId = data.presentedSymptomId !== undefined ? data.presentedSymptomId : null;
            this.currentCondition = data.currentCondition !== undefined ? data.currentCondition : null;
            this.liveWith = data.liveWith !== undefined ? data.liveWith : null;
            this.familySymptomId = data.familySymptomId !== undefined ? data.familySymptomId : null;
            this.assessedBiological = data.assessedBiological !== undefined ? data.assessedBiological : null;
            this.transportationIds = data.transportationIds !== undefined ? data.transportationIds : null;
            this.liveWithIds = data.liveWithIds !== undefined ? data.liveWithIds : null;
            this.hasIssueReturningWork = data.hasIssueReturningWork !== undefined ? data.hasIssueReturningWork : null;
            this.occupation = data.occupation !== undefined ? data.occupation : null;
            this.boss = data.boss !== undefined ? data.boss : null;
            this.organizationAreaId = data.organizationAreaId !== undefined ? data.organizationAreaId : null;
            this.taskId = data.taskId !== undefined ? data.taskId : null;
            this.executedAt = data.executedAt !== undefined ? data.executedAt : null;
            this.issueReturningWork = data.issueReturningWork !== undefined ? data.issueReturningWork : null;
            this.telecommutingHealthHabit = data.telecommutingHealthHabit !== undefined ? data.telecommutingHealthHabit : null;
            this.telecommutinWorkload = data.telecommutinWorkload !== undefined ? data.telecommutinWorkload : null;
            this.benefitInQuarantine = data.benefitInQuarantine !== undefined ? data.benefitInQuarantine : null;
            this.difficultyInQuarantine = data.difficultyInQuarantine !== undefined ? data.difficultyInQuarantine : null;
            this.observation = data.observation !== undefined ? data.observation : null;
            this.files = data.files !== undefined ? data.files : null;
            this.weight = data.weight !== undefined ? data.weight : null;
            this.height = data.height !== undefined ? data.height : null;
            this.age = data.age !== undefined ? data.age : null;
            this.isASmoker = data.isASmoker !== undefined ? data.isASmoker : null;
        }
     }

     static fromJS(data: any): EmployeeHealthSurveySchema {
         return new EmployeeHealthSurveySchema(data);
     }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['employeeId'] = this.employeeId !== undefined ? this.employeeId : null;
        data['fullName'] = this.fullName !== undefined ? this.fullName : null;
        data['identificationNumber'] = this.identificationNumber !== undefined ? this.identificationNumber : null;
        data['identificationTypeId'] = this.identificationTypeId !== undefined ? this.identificationTypeId : null;
        data['cellphoneNumber'] = this.cellphoneNumber !== undefined ? this.cellphoneNumber : null;
        data['email'] = this.email !== undefined ? this.email : null;
        data['verificationCode'] = this.verificationCode !== undefined ? this.verificationCode : null;
        data['healthState'] = this.healthState !== undefined ? this.healthState : null;
        data['highRiskDisease'] = this.highRiskDisease !== undefined ? this.highRiskDisease : null;
        data['diseaseId'] = this.diseaseId !== undefined ? this.diseaseId : null;
        data['presentedSymptomId'] = this.presentedSymptomId !== undefined ? this.presentedSymptomId : null;
        data['currentCondition'] = this.currentCondition !== undefined ? this.currentCondition : null;
        data['liveWith'] = this.liveWith !== undefined ? this.liveWith : null;
        data['familySymptomId'] = this.familySymptomId !== undefined ? this.familySymptomId : null;
        data['assessedBiological'] = this.assessedBiological !== undefined ? this.assessedBiological : null;
        data['transportationIds'] = this.transportationIds !== undefined ? this.transportationIds : null;
        data['hasIssueReturningWork'] = this.hasIssueReturningWork !== undefined ? this.hasIssueReturningWork : null;
        data['occupation'] = this.occupation !== undefined ? this.occupation : null;
        data['boss'] = this.boss !== undefined ? this.boss : null;
        data['organizationAreaId'] = this.organizationAreaId !== undefined ? this.organizationAreaId : null;
        data['taskId'] = this.taskId !== undefined ? this.taskId : null;
        data['executedAt'] = this.executedAt !== undefined ? this.executedAt : null;
        data['issueReturningWork'] = this.issueReturningWork !== undefined ? this.issueReturningWork : null;
        data['telecommutingHealthHabit'] = this.telecommutingHealthHabit !== undefined ? this.telecommutingHealthHabit : null;
        data['telecommutinWorkload'] = this.telecommutinWorkload !== undefined ? this.telecommutinWorkload : null;
        data['benefitInQuarantine'] = this.benefitInQuarantine !== undefined ? this.benefitInQuarantine : null;
        data['difficultyInQuarantine'] = this.difficultyInQuarantine !== undefined ? this.difficultyInQuarantine : null;
        data['observation'] = this.observation !== undefined ? this.observation : null;
        data['files'] = this.files !== undefined ? this.files : null;
        return data;
     }

     toJSON() {
         return JSON.stringify(this.toJS());
     }

    toFormData() {
        const formData: FormData = new FormData();
        formData.append('employeeId', this.employeeId.toString());
        formData.append('fullName', this.fullName);
        formData.append('identificationNumber', this.identificationNumber);
        if (this.identificationTypeId) {
            formData.append('identificationTypeId', this.identificationTypeId.toString());
        }

        formData.append('cellphoneNumber', this.cellphoneNumber);
        formData.append('email', this.email);
        formData.append('verificationCode', this.verificationCode);
        formData.append('healthState', this.healthState);
        formData.append('highRiskDisease', this.highRiskDisease);
        if (this.diseaseId) {
            formData.append('diseaseId', this.diseaseId.toString());
        }
        if(this.presentedSymptomId)
            formData.append('presentedSymptomId', this.presentedSymptomId.toString());
        formData.append('currentCondition', this.currentCondition);
        formData.append('liveWith', this.liveWith);
        formData.append('familySymptomId', this.familySymptomId.toString());
        formData.append('assessedBiological', this.assessedBiological);
        if ( this.transportationIds != null && typeof(this.transportationIds) === 'object' ) {
            this.transportationIds = this.transportationIds.join(',');
        }
        formData.append('transportationIds', this.transportationIds);
        formData.append('occupation', this.occupation);
        formData.append('boss', this.boss);
        formData.append('organizationAreaId', this.organizationAreaId.toString());
        formData.append('taskId', this.taskId.toString());
        formData.append('executedAt', this.executedAt);
        formData.append('hasIssueReturningWork', this.hasIssueReturningWork);
        if (this.issueReturningWork) {
            formData.append('issueReturningWork', this.issueReturningWork);
        }

        if (this.telecommutingHealthHabit) {
            formData.append('telecommutingHealthHabit', this.telecommutingHealthHabit);
        }
        if (this.telecommutinWorkload) {
            formData.append('telecommutinWorkload', this.telecommutinWorkload);
        }
        if (this.benefitInQuarantine) {
            formData.append('benefitInQuarantine', this.benefitInQuarantine);
        }
        if (this.difficultyInQuarantine) {
            formData.append('difficultyInQuarantine', this.difficultyInQuarantine);
        }
        if (this.observation) {
            formData.append('observation', this.observation);
        }

        if (this.files) {
            this.files.forEach(file => {
                formData.append('files', file._file);
            });
        }
        return formData;
     }

     clone() {
         const json = this.toJSON();
         return new EmployeeHealthSurveySchema(JSON.parse(json));
     }

}
