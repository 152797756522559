import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UiService } from 'src/app/_services/ui.service';
import { HttpService } from 'src/app/_services/http.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { OrganizationSchema, OrganizationArea } from 'src/app/_schemas/organization-schema';
import { DomSanitizer } from '@angular/platform-browser';
import { FileService } from 'src/app/_services/file.service';
import { ConfirmComponent } from 'src/app/_modules/share/_components/confirm/confirm.component';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { Router } from '@angular/router';

declare var jQuery: any;
import * as d3 from 'd3';
import { AddOrganizationAreaComponent } from '../add-organization-area/add-organization-area.component';
import { OrganizationAreaDetailsComponent } from '../organization-area-details/organization-area-details.component';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})
export class OrganizationDetailsComponent implements OnInit, OnDestroy {


  organizationSubscribe: Subscription;
  subscriptions: Subscription[] = [];

  organizationId: number;
  organization: OrganizationSchema;

  selectedArea: OrganizationArea;

  role: string;

  showModal: boolean;
  dataLoaded: boolean;
  showProgressBar: boolean;

  // modal for templates
  bsModalRef: BsModalRef;

  // preview report
  previewFileURL;
  reportFileLoadedCount: number;

  // carousel galeria
  selectedFileIndex: number;
  selectedImage: any;
  isUploadingFile: boolean;

  // tranfer entities
  entityTransferId: any;

  constructor(
    private userService: UserService,
    private modalService: BsModalService,
    public mainBsModalRef: BsModalRef,
    public uiService: UiService, // used in html template
    private httpService: HttpService,
    public fileService: FileService, // used in html template
    private toastr: ToastrService,
  ) {
    this.modalService.onHide.subscribe((reason: string) => {
      this.showModal = true;
    });
   }

  ngOnInit() {

    this.showModal = true;
    this.dataLoaded = false;
    this.showProgressBar = false;
    this.selectedFileIndex = 0;
    this.isUploadingFile = false;

    this.organizationSubscribe = this.userService.selectedOrganization$.subscribe( organization => {
        if ( organization) {
          this.dataLoaded = true;
          this.organization = organization;
        } else {
          this.httpService.get(environment.endpoints.organization + this.organizationId)
          .subscribe( (organizationRes) => {
                this.organization = organizationRes;
                this.dataLoaded = true;
          });
        }
    });
  }

  ngOnDestroy(): void {
    if ( this.organizationSubscribe ) {
      this.organizationSubscribe.unsubscribe();
    }
  }

  showPolygon(template: TemplateRef<any>) {
    this.showModal = false;
    this.bsModalRef = this.modalService.show(template, {
      class: 'modal-lg'
    });

    this.drawOrganizationPolygon();
  }

  drawOrganizationPolygon() {

    const svg = d3.select('#organizationPolygon svg');
    const svgWidth = parseInt(svg.style('width'), 10);
    const svgHeight = parseInt(svg.style('height'), 10);
    const projection = d3.geoMercator().scale(1);
    const path = d3.geoPath().projection(projection);
    const bounds = path.bounds(this.organization.organizationPolygon);
    const scale = 0.9 / Math.max(
                   (bounds[1][0] - bounds[0][0]) / svgWidth,
                   (bounds[1][1] - bounds[0][1]) / svgHeight
               );
    const geoBounds = d3.geoBounds(this.organization.organizationPolygon);

    projection.scale(scale);
    projection.center([(geoBounds[1][0] + geoBounds[0][0]) / 2, (geoBounds[1][1] + geoBounds[0][1]) / 2]);
    projection.translate([svgWidth / 2, svgHeight / 2]);

    svg.strokeStyle = '#aaa';
    svg.append('path')
      .attr('d', path(this.organization.organizationPolygon))
      .attr('stroke', 'red')
      .attr('stroke-width', 3)
      .attr('stroke-opacity', 1)
      .attr('fill', 'red')
      .attr('fill-opacity', '0.2');

    // organizacion name
    svg.append('text')
      .attr('style', 'font: bold 20px sans-serif;')
      .attr('x',  svgWidth * 0.5)
      .attr('y', svgHeight * 0.1)
      .attr('text-anchor', 'middle')
      .text(this.organization.name);

    // organization areas
    if ( this.organization.organizationAreaList.length > 0) {
      const  areasText = svg.append('text')
      .attr('style', 'font: italic 15px sans-serif;')
      .attr('x',  svgWidth * 0.1)
      .attr('y', svgHeight * 0.3)
      .attr('text-anchor', 'start')
      .text('Áreas de la empresa: ');

      this.organization.organizationAreaList.forEach(thisArea => {

        areasText.append('tspan')
        .attr('style', 'font: normal 15px sans-serif;')
        .attr('x',  svgWidth * 0.1)
        .attr('dy', 25)
        .attr('text-anchor', 'start')
        .text('- ' +  thisArea.name);
      });
    }
  }

  /**
   * open add organization area modal
   */
  addArea() {

    const initialState = {
        organizationId: this.organizationId,
    };
    this.bsModalRef = this.modalService.show(AddOrganizationAreaComponent, {
        class: 'modal-lg', initialState,
    });

    this.showModal = false;
    this.bsModalRef.content.reloadOrganizationEvent.subscribe(
      (organization) => {
        this.showModal = true;
        this.userService.setOrganization(organization);
      }
    );
  }

  removeArea(organizationAreaId) {

    const context = this;
    this.showModal = false;
    const initialState = {
      modalDataInput: {
        message: 'La operación que desea realizar es permanente y eliminará todos los los datos asociados.'
        + ' ¿Esta seguro que desea eliminar el área seleccionada?',
        okBtnText: 'Sí',
        cancelBtnText: 'No',
      },
    };

    this.bsModalRef = this.modalService.show(ConfirmComponent, {
        class: 'modal-sm', initialState,
    });
    this.bsModalRef.content.returnModalResponseEvent.subscribe(
      (response) => {
        context.showModal = true;
        if (response === 'ok') {
          context.showProgressBar = true;
          context.httpService.delete(environment.endpoints.removeOrganizationArea, organizationAreaId).subscribe( (res) => {
            // context.risk = res.data;
            context.toastr.success('El área ha sido eliminada');
            context.showProgressBar = false;
            this.userService.setOrganization(res.data);
          },
          error => {
            context.toastr.error('Error del servidor');
            context.showProgressBar = false;
          });
        }
      }
    );
  }

  /**
   * open organization area details
   */
  showAreaDetails(area: OrganizationArea) {

    const initialState = {
        organizationId: this.organizationId,
        organizationAreaId: area.organizationAreaId,
        name: area.name,
        description: area.description,
        userResponsable: area.userResponsable,
        emailUserResponsable: area.emailUserResponsable
    };
    this.bsModalRef = this.modalService.show(OrganizationAreaDetailsComponent, {
        class: 'modal-lg', initialState,
    });

    this.showModal = false;
    this.bsModalRef.content.reloadOrganizationEvent.subscribe(
      (organization) => {
        this.showModal = true;
        this.userService.setOrganization(organization);
      }
    );
  }

  /**
   * open organization area details
   */
  showGallery(template: TemplateRef<any>, area: OrganizationArea) {

    this.selectedArea = area;
    this.selectedFileIndex = 0;
    this.bsModalRef = this.modalService.show(template, {
        class: 'modal-lg'
    });

    this.showModal = false;
  }

  /**
   * upload organization area file
   */
  uploadFile(e: any) {

    const file: File = e.target.files[0];
    if (file) {
        // it only accepts 5 MB or less
        if (file.size > 1024 * 5000) {
            this.toastr.warning('No se permiten imágenes mayores a 5 MB');
            return;
        }
        this.isUploadingFile = true;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('organizationAreaId', this.selectedArea.organizationAreaId.toString());


        this.httpService.saveFD(environment.endpoints.uploadOrganizationAreaFile, formData).subscribe( (res) => {
          this.toastr.success('Archivo subido');
          this.userService.setOrganization(res.data);
          this.isUploadingFile = false;
          this.bsModalRef.hide();
        },
        (error) => {
          this.toastr.error('Error del servidor');
          this.isUploadingFile = false;
        });
    }
  }

  /**
   * carousel button event
   */
  changeCarouselItem(left: boolean): void {

    const totalFiles = this.selectedArea.fileList.length + 1;

    if (left) {
      this.selectedFileIndex = (((this.selectedFileIndex - 1 + totalFiles)) % totalFiles);
    } else {
      this.selectedFileIndex = ((this.selectedFileIndex + 1) % totalFiles);
    }
  }

  /**
   * show full screen image
   */
  imageFullScreen(template: TemplateRef<any>, file: any): void {
    this.selectedImage = file;
    jQuery('#progressFullScreen').css('display', 'block');
    this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  fullScreenLoaded() {
    jQuery('#progressFullScreen').css('display', 'none');
  }

}
