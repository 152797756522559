import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  // main redirect
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'oauth', loadChildren: './_modules/oauth/oauth.module#OauthModule' },
  { path: 'index', loadChildren: './_modules/index/index.module#IndexModule' },
  { path: 'publicRisk', loadChildren: './_modules/public-risk/public-risk.module#PublicRiskModule' },
  { path: 'landing', loadChildren: './_modules/landing/landing.module#LandingModule' },
  { path: 'employees', loadChildren: './_modules/employees/employees.module#EmployeesModule' },
  // Handle all other routes
  { path: '**', redirectTo: 'index' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
