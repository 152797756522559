import { Component, OnInit, TemplateRef } from '@angular/core';
import { Chart } from 'chart.js';
import { HttpService } from 'src/app/_services/http.service';
import { environment } from 'src/environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-risks-charts',
  templateUrl: './risks-charts.component.html',
  styleUrls: ['./risks-charts.component.scss']
})
export class RisksChartsComponent implements OnInit {

  geoChart: Chart = null;
  ctxGeo;
  canvasGeo;
  dataChartGeo;

  usersChart: Chart = null;
  ctxUsers;
  canvasUsers;
  dataChartUsers;

  riskTypesChart: Chart = null;
  ctxRiskTypes;
  canvasRiskTypes;
  dataChartRiskTypes;

  fullChart: Chart = null;
  ctxFull;
  canvasFull;
  dataChartFull;

  // modal for templates
  bsModalRef: BsModalRef;
  modalTitle: string;
  subscriptions: Subscription[] = [];

  showProgressBar = true;

  constructor(
    private httpService: HttpService,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    // datos geografico
    this.httpService.get(environment.endpoints.chart.getRisksGroupDepartment).subscribe((res: any) => {
      this.dataChartGeo = {
        type: 'pie',
        data: res.data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
              display: true,
              text: 'Riesgos por departamento',
          },
          legend: {
            display: false,
          },
        }
      };
      this.canvasGeo = document.getElementById('geoChart');
      this.ctxGeo = this.canvasGeo.getContext('2d');
      this.geoChart = new Chart(this.ctxGeo, this.dataChartGeo);
    });

    // datos por usuario
    this.httpService.get(environment.endpoints.chart.getRisksGroupUsers).subscribe((res: any) => {
      this.showProgressBar = false;
      this.dataChartUsers = {
        type: 'pie',
        data: res.data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
              display: true,
              text: 'Riesgos por usuario',
          },
          legend: {
            display: false,
          },
        }
      };
      this.canvasUsers = document.getElementById('usersChart');
      this.ctxUsers = this.canvasUsers.getContext('2d');
      this.usersChart = new Chart(this.ctxUsers, this.dataChartUsers );
    });

    // datos por tipos de riesgos
    this.httpService.get(environment.endpoints.chart.getRisksGroupRiskTypes).subscribe((res: any) => {
      this.dataChartRiskTypes = {
        type: 'pie',
        data: res.data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
              display: true,
              text: 'Riesgos por tipo',
          },
          legend: {
            display: false,
          },
        }
      };
      this.canvasRiskTypes = document.getElementById('riskTypesChart');
      this.ctxRiskTypes = this.canvasRiskTypes.getContext('2d');
      this.riskTypesChart = new Chart(this.ctxRiskTypes, this.dataChartRiskTypes);
    });
  }


  showFullChart(template: TemplateRef<any>, chart: string ) {

    /*this.subscriptions.push(
      this.modalService.onShown.subscribe((reason: string) => {

        this.canvasFull = document.getElementById('chartFull');
        this.ctxFull = this.canvasFull.getContext('2d');
        let data;
        switch (chart) {
          case 'geo':
            this.modalTitle = 'Riesgos por departamento';
            data = this.dataChartGeo;
            break;
          case 'users':
            this.modalTitle = 'Riesgos por usuario';
            data = this.dataChartUsers;
            break;
          case 'types':
            this.modalTitle = 'Riesgos por tipo';
            data = this.dataChartRiskTypes;
            break;
        }
        this.dataChartFull = data;
        this.dataChartFull.options = {
          responsive: true,
          maintainAspectRatio: false,
          title: {
              display: false,
          },
          legend: {
            display: true,
            position: 'left'
          },
        };
        this.fullChart = new Chart(this.ctxFull, this.dataChartFull);
      })
    );*/

    this.modalTitle = 'Riesgos';

    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        this.unsubscribe();
      })
    );

    this.bsModalRef = this.modalService.show(template, {
      class: 'modal-lg'
    });

  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}
