import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/_services/http.service';
import { environment } from 'src/environments/environment';
import { HealthSurvey, EmployeeSchema } from 'src/app/_schemas/employee-schema';
import { BsModalRef } from 'ngx-bootstrap';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { EmployeeHealthSurveySchema } from 'src/app/_schemas/employee-health-survey-schema';
import { Subscription } from 'rxjs/internal/Subscription';
import { UserService } from 'src/app/_services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-health-condition',
  templateUrl: './modal-health-condition.component.html',
  styleUrls: ['./modal-health-condition.component.scss']
})
export class ModalHealthConditionComponent implements OnInit {

  employeeSubscribe: Subscription;
  employee: EmployeeSchema;

  diseases: any = [];
  symptoms: any = [];
  transportations: any = [];
  organizationAreas: any = [];

  employeeHealthSurveySchema: EmployeeHealthSurveySchema;
  employeeForm: FormGroup;
  healthForm: FormGroup;

  // form: FormGroup;
  healthConditionId: any;
  userId: number;
  // health: HealthSurvey = <HealthSurvey>{};
  health: HealthSurvey;
  showLoading = true;
  healthStates = [];
  highRiskDiseases = [];
  controlDiseases = [];
  controlSymptoms = [];
  currentConditions = [];
  liveWith = [];
  assessedBiological = [];
  controlTransportations = [];
  hasIssueReturningWork = [];


  constructor(private httpService: HttpService,
    private bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService) {

    this.healthForm = this.formBuilder.group({
      healthStates: new FormArray([]),
      highRiskDiseases: new FormArray([]),
      diseases: new FormArray([]),
      presentedSymptoms: new FormArray([]),
      currentConditions: new FormArray([]),
      liveWith: new FormArray([]),
      familySymptoms: new FormArray([]),
      assessedBiological: new FormArray([]),
      transportation: new FormArray([]),
      hasIssueReturningWork: new FormArray([]),
      issueReturningWork: new FormControl(),
      telecommutingHealthHabit: new FormControl(),
      telecommutinWorkload: new FormControl(),
      benefitInQuarantine: new FormControl(),
      difficultyInQuarantine: new FormControl(),
      observation: new FormControl(),
    });
  }

  ngOnInit() {
    this.httpService.get(environment.endpoints.getByUsername + this.userId).subscribe(res => {
      this.employee = res.data;
      this.loadInitData();
    });
  }

  
  getAnswersHealthSurvey() {
    this.httpService.get(environment.endpoints.getHealthSurveyById + this.healthConditionId).subscribe(res => {
      this.health = res.data;
      this.addHealthStates();
      this.addHighRiskDisease();
      this.addDiseases();
      this.addPresentedSymptoms();
      this.addCurrentCondition();
      this.addLiveWith();
      this.addFamilySymptom();
      this.addAssessedBiological();
      this.addTransportation();
      this.addHasIssueReturningWork();
      this.healthForm.controls.issueReturningWork.disable();
      this.healthForm.controls.issueReturningWork.setValue(this.health.issueReturningWork);
      this.healthForm.controls.telecommutingHealthHabit.disable();
      this.healthForm.controls.telecommutingHealthHabit.setValue(this.health.telecommutingHealthHabit);
      this.healthForm.controls.telecommutinWorkload.disable();
      this.healthForm.controls.telecommutinWorkload.setValue(this.health.telecommutinWorkload);
      this.healthForm.controls.benefitInQuarantine.disable();
      this.healthForm.controls.benefitInQuarantine.setValue(this.health.benefitInQuarantine);
      this.healthForm.controls.difficultyInQuarantine.disable();
      this.healthForm.controls.difficultyInQuarantine.setValue(this.health.difficultyInQuarantine);
      this.healthForm.controls.observation.disable();
      this.healthForm.controls.observation.setValue(this.health.observation);
      this.showLoading = false;
    });
  }

  loadInitData() {

    // this.isLoading = true;
    let url = environment.endpoints.employee_initialData
    .replace('{organizationId}', this.employee.organizationUser.organization.organizationId.toString());
    url = url.replace('{employeeId}', this.employee.employeeId.toString());
    this.httpService.get( url ).subscribe((response) => {
      if ( response.status === 'success' ) {
        this.diseases = response.data.diseases;
        this.symptoms = response.data.symptoms;
        this.transportations = response.data.transportations;
        this.organizationAreas = response.data.organizationAreas;

        this.employeeHealthSurveySchema = new EmployeeHealthSurveySchema(
          {
            employeeId: this.employee.employeeId,
            fullName: response.data.employee.fullName,
            identificationNumber: response.data.employee.identificationNumber,
            cellphoneNumber: response.data.employee.cellPhoneNumber,
            email: this.employee.email,
            organizationAreaId: (response.data.employee.organizationArea)
                                ? response.data.employee.organizationArea.organizationAreaId : null,
            occupation: response.data.employee.occupation,
            boss: response.data.employee.boss
          }
        );

        this.employeeForm = this.formBuilder.group(this.employeeHealthSurveySchema);
        // disable fields
        this.employeeForm.controls.email.disable();
          this.employeeForm.controls.fullName.disable();
          this.employeeForm.controls.identificationNumber.disable();
          this.employeeForm.controls.cellphoneNumber.disable();
          this.employeeForm.controls.organizationAreaId.disable();
          this.employeeForm.controls.occupation.disable();
          this.employeeForm.controls.boss.disable();
        // this.showLoading = false;
        this.getAnswersHealthSurvey();

      } else {
        this.toastr.error('Ocurrió un error cargando la configuración inicial, por favor intenta de nuevo');
        // this.isLoading = false;
        // this.goBack();
      }
    }, (e) => {
      // this.toastr.error('Error del servidor, por favor intenta de nuevo');
      // this.isLoading = false;
      // this.goBack();
    });
  }


  private addHealthStates() {
      let obj = {id: "Bueno", name: "Bueno"};
      this.healthStates.push(obj);
      obj = {id: "Aceptable", name: "Aceptable"};
      this.healthStates.push(obj);
      obj = {id: "Regular", name: "Regular"};
      this.healthStates.push(obj);
      obj = {id: "Mal", name: "Mal"};
      this.healthStates.push(obj);
    this.healthStates.map((o, i) => {
      let checked = false;
      if (o.id === this.health.healthState) {
        checked = true;
      }
      const control = new FormControl({ value: checked, disabled: true });
      (this.healthForm.controls.healthStates as FormArray).push(control);
    });
    
  }

  private addHighRiskDisease() {

    let obj = {id: "Si", name: "Si"};
    this.highRiskDiseases.push(obj);
    obj = {id: "No", name: "No"};
    this.highRiskDiseases.push(obj);
    this.highRiskDiseases.map((o, i) => {
      let checked = false;
      if (o.id === this.health.highRiskDisease) {
        checked = true;
      }
      const control = new FormControl({ value: checked, disabled: true });
      (this.healthForm.controls.highRiskDiseases as FormArray).push(control);
    });
  }

  private addDiseases() {
    // mapear el array a {id, name} objects para que se pueda mostrar como checkbox
    this.diseases.forEach(element => {
      const obj = {id: element.diseaseId, name: element.name};
      this.controlDiseases.push(obj);
    });

    this.controlDiseases.map((o, i) => {
      let checked = false;
            if (o.id === this.health.diseaseId) {
              checked = true;
          }
      const control = new FormControl({value: checked, disabled: true});
      (this.healthForm.controls.diseases as FormArray).push(control);
    });
  }

  private addPresentedSymptoms() {
      // mapear el array a {id, name} objects para que se pueda mostrar como checkbox
      this.symptoms.forEach(element => {
        const obj = {id: element.symptomId, name: element.name};
        this.controlSymptoms.push(obj);
      });
  
      this.controlSymptoms.map((o, i) => {
        let checked = false;
              if (o.id === this.health.presentedSymptomId.symptomId) {
                checked = true;
            }
        const control = new FormControl({value: checked, disabled: true});
        (this.healthForm.controls.presentedSymptoms as FormArray).push(control);
      });
  }

  private addCurrentCondition() {
    let obj = {id: "Tiene más de 70 años", name: "Tiene más de 70 años"};
    this.currentConditions.push(obj);
    obj = {id: "Usted se encuentra en estado de embarazo", name: "Usted se encuentra en estado de embarazo"};
    this.currentConditions.push(obj);
    obj = {id: "No aplica", name: "No aplica"};
    this.currentConditions.push(obj);
  this.currentConditions.map((o, i) => {
    let checked = false;
    if (o.id === this.health.currentCondition) {
      checked = true;
    }
    const control = new FormControl({ value: checked, disabled: true });
    (this.healthForm.controls.currentConditions as FormArray).push(control);
  });
  
}

  private addLiveWith() {
    
    let obj = {id: "Personas mayores de 70 años", name: "Personas mayores de 70 años"};
    this.liveWith.push(obj);
    obj = {id: "Mujer en estado de gestación", name: "Mujer en estado de gestación"};
    this.liveWith.push(obj);
    obj = {id: "Niños", name: "Niños"};
    this.liveWith.push(obj);
    obj = {id: "Personas con enfermedades de alto riesgo", name: "Personas con enfermedades de alto riesgo"};
    this.liveWith.push(obj);
    obj = {id: "Personal de salud", name: "Personal de salud"};
    this.liveWith.push(obj);
    obj = {id: "Personas que tienen exposición directa a riesgos biológicos", name: "Personas que tienen exposición directa a riesgos biológicos"};
    this.liveWith.push(obj);
    obj = {id: "Ninguna de las anteriores", name: "Ninguna de las anteriores"};
    this.liveWith.push(obj);
  this.liveWith.map((o, i) => {
    let checked = false;
    if (o.id === this.health.liveWith) {
      checked = true;
    }
    const control = new FormControl({ value: checked, disabled: true });
    (this.healthForm.controls.liveWith as FormArray).push(control);
  });
  }

  private addFamilySymptom() {
      this.controlSymptoms.map((o, i) => {
        let checked = false;
              if (o.id === this.health.familySymptomId.symptomId) {
                checked = true;
            }
        const control = new FormControl({value: checked, disabled: true});
        (this.healthForm.controls.familySymptoms as FormArray).push(control);
      });
  }

  private addAssessedBiological() {

    let obj = {id: "Si", name: "Si"};
    this.assessedBiological.push(obj);
    obj = {id: "No", name: "No"};
    this.assessedBiological.push(obj);
    this.assessedBiological.map((o, i) => {
      let checked = false;
      if (o.id === this.health.assessedCovid) {
        checked = true;
      }
      const control = new FormControl({ value: checked, disabled: true });
      (this.healthForm.controls.assessedBiological as FormArray).push(control);
    });
  }

  private addTransportation() {

    // mapear el array a {id, name} objects para que se pueda mostrar como checkbox
    this.transportations.forEach(element => {
      const obj = {id: element.transportationId, name: element.name};
      this.controlTransportations.push(obj);
    });

    this.controlTransportations.map((o, i) => {
      let checked = false;
      if (this.health.healthConditionTransportationList) {
        this.health.healthConditionTransportationList.forEach(element => {
            if (o.id === element.transportation.transportationId) {
              checked = true;
          }
        });
      }
      const control = new FormControl({value: checked, disabled: true});
      (this.healthForm.controls.transportation as FormArray).push(control);
    });
}

private addHasIssueReturningWork() {

  let obj = {id: "Si", name: "Si"};
  this.hasIssueReturningWork.push(obj);
  obj = {id: "No", name: "No"};
  this.hasIssueReturningWork.push(obj);
  this.hasIssueReturningWork.map((o, i) => {
    let checked = false;
    if (o.id === this.health.hasIssueReturningWork) {
      checked = true;
    }
    const control = new FormControl({ value: checked, disabled: true });
    (this.healthForm.controls.hasIssueReturningWork as FormArray).push(control);
  });
}
  closeModal() {
    this.bsModalRef.hide();
  }

}
