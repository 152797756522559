import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from 'primeng/api';
import { HttpService } from 'src/app/_services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-task-management',
  templateUrl: './user-task-management.component.html',
  styleUrls: ['./user-task-management.component.scss']
})
export class UserTaskManagementComponent implements OnInit {

  taskTree: TreeNode[];
  user: any;
  editable = false;
  tasksToChange = [];
  isLoading = false;

  constructor(private httpService: HttpService,
    private toastr: ToastrService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.buildNodes();
  }

  closeModal() {
    this.bsModalRef.hide();
    this.modalService._hideModal(1);
  }

  buildNodes() {
    const url = environment.endpoints.getTaskByAssignTo.replace('{assignTo}', this.user.email);
    this.httpService.get(url).subscribe(res => {
      let tasks = res;
      if (tasks.length > 0) {
        const taskNodes = [];
        tasks.map((task, index) => {
          let taskChildren = [];
          taskChildren = this.mapChildrenNodes(task);

          const tempTask = {
            id: task.id,
            text: task.taskTemplateId.text,
            status: task.status,
            statusEdited: task.status
          };
          taskNodes.push({ data: tempTask, children: taskChildren, expanded: true });
        });
        this.taskTree = taskNodes;
      }
    });
  }

  private mapChildrenNodes(task) {
    if (!task.taskList)
      return [];

    return this.childrenNodes(task);
  }

  private childrenNodes(task) {
    let taskChildren = [];
    let childs = [];
    task.taskList.map(child => {
      childs = this.mapChildrenNodes(child);
      const tempChild = {
        id: child.id,
        text: child.taskTemplateId.text,
        status: child.status,
        statusEdited: child.status
      };

      taskChildren.push({ data: tempChild, children: childs });
    });
    return taskChildren;
  }

  showEditable() {
    this.editable = !this.editable;
  }

  buildTaskList(rowData) {
    if (rowData.statusEdited !== rowData.status) {
      let taskToChange = {
        id: rowData.id,
        status: rowData.statusEdited
      }
      this.tasksToChange.push(taskToChange);
    } else {

      this.tasksToChange.splice(this.findTaskById(rowData.id), 1);
    }

  }

  private findTaskById(id) {
    const index = this.tasksToChange.findIndex(task => task.id === id);
    return index;
  }

  updateStatus() {
    if (this.tasksToChange.length === 0) {
      this.editable = false;
    } else {
      this.isLoading = true;
      this.httpService.save(environment.endpoints.updateStatusTasks,
        this.tasksToChange).subscribe( async (response) => {
          if ( response['status'] === 'success' ) {
            this.toastr.success('Guardado exitosamente');
            this.editable = false;
            this.bsModalRef.hide();
          } else {
            this.toastr.error('Algo malo pasó, por favor intenta de nuevo.');
          }
          this.isLoading = false;
        }, (e) => {
          this.isLoading = false;
          this.toastr.error('Error del servidor, por favor intenta de nuevo.');
         }
      );
    }
  }
}
