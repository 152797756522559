import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { map, tap, catchError  } from 'rxjs/operators';

import { TokenStorageService } from './token-storage.service';
import { AuthSchema } from 'src/app/_schemas/auth-schema';
import { UserSchema, UserSignup } from 'src/app/_schemas/user-schema';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, throwError } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationService implements AuthService {

    private headers: HttpHeaders;
    user: UserSchema = new UserSchema();

    public tokenSubject = new BehaviorSubject<string>(undefined);
    public token$ = this.tokenSubject.asObservable();

    /* Authentication handle (https://github.com/serhiisol/ngx-auth) */
    constructor(
        private http: HttpClient,
        private tokenStorage: TokenStorageService,
    ) {

        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
        this.headers = this.headers.append('Authorization', 'Basic c3ByaW5nLXNlY3VyaXR5LW9hdXRoMi1yZWFkLXdyaXR'
        + 'lLWNsaWVudDpzcHJpbmctc2VjdXJpdHktb2F1dGgyLXJlYWQtd3JpdGUtY2xpZW50LXBhc3N3b3JkMTIzNA==');
    }

    /**
     * Check, if user already authorized.
     * @description Should return Observable with true or false values
     * @returns Observable<boolean>
     * @memberOf AuthService
     */
    public isAuthorized(): Observable<boolean> {
        return this.tokenStorage
            .getAccessToken()
            .pipe(
                map(token => {
                    if (token ) {
                        return true;
                    } else {
                        return false;
                    }
                })
            );
    }

    /**
     * Get access token
     * @description Should return access token in Observable from e.g.
     * localStorage
     * @returns Observable<string>
     */
    public getAccessToken(): Observable<string> {
        return this.tokenStorage.getAccessToken();
    }

    /**
     * Function, that should perform refresh token verifyTokenRequest
     * @description Should be successfully completed so interceptor
     * can execute pending requests or retry original one
     * @returns Observable<AuthSchema>
     */
    public refreshToken(): Observable<any> {
        const refreshToken: string = this.tokenStorage.getRefreshTokenString();

        const params = new URLSearchParams();
        params.append('grant_type', 'refresh_token');
        params.append('refresh_token', refreshToken);

        return this.http.post(environment.endpoints.token, params.toString(), { headers: this.headers })
        .pipe(
            tap(
                (tokens: AuthSchema) => {
                    this.saveTokens(tokens.access_token, tokens.refresh_token);
                }
            ),
            catchError((err) => {
                this.logout();
                return throwError(new Error((err)));
            })
        );
    }

    /**
     * Function, checks response of failed request to determine,
     * whether token be refreshed or not.
     * @description Essentialy checks status
     * @param Response response
     * @returns boolean
     */
    public refreshShouldHappen(response: HttpErrorResponse): boolean {
        return response.status === 401;
    }

    /**
     * Verify that outgoing request is refresh-token,
     * so interceptor won't intercept this request
     * @param string url
     * @returns boolean
     */
    public verifyTokenRequest(url: string): boolean {
        return url.endsWith('oauth/token');
    }

    /**
     * log in service
     */
    public Login(userSchema: UserSchema): Observable<any> {
        this.clearTokens();
        const params = new URLSearchParams();
        params.append('grant_type', 'password');
        params.append('username', userSchema.user.username);
        params.append('password', userSchema.user.password);

        return this.http.post(environment.endpoints.token, params.toString(), { headers: this.headers })
        .pipe(
            tap(
                (tokens: AuthSchema) => this.saveTokens(tokens.access_token, tokens.refresh_token)
            ),
        );
    }

    /**
     * signup service
     */
    public signup(userSignup: UserSignup): Observable<any> {
        return this.http.post(environment.endpoints.createUser, userSignup.toJS());
    }

    /**
     * saving tokens (for external OAuth 2.0 login)
     */
    public saveTokens(accessToken: string, refreshToken: string) {
        this.tokenStorage
        .setAccessToken(accessToken)
        .setRefreshToken(refreshToken);
        this.tokenSubject.next(accessToken);
    }

    /**
     * log out
     */
    public logout() {
        this.tokenStorage.clear();
        location.reload(true);
    }

    /**
     * clear tokens
     */
    public clearTokens() {
        this.tokenStorage.clear();
        this.tokenSubject.next(null);
    }
}
