/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpService } from 'src/app/_services/http.service';
import { TokenStorageService } from 'src/app/_services/authentication/token-storage.service';
import { UserService } from 'src/app/_services/user.service';
import { RiskSchema } from 'src/app/_schemas/risk-schema';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

declare let google: any;

@Component({
  selector: 'app-google-map-modal',
  templateUrl: './google-map-modal.component.html',
  styleUrls: ['./google-map-modal.component.scss']
})
export class GoogleMapModalComponent implements OnInit, OnDestroy {
  

  @ViewChild('gmap', {static: true}) gmapElement: any;
  map: google.maps.Map;

  // initial coordinates
  lat = 5.378471;
  lng = -74.256520;

  infowindow = new google.maps.InfoWindow();
  loadMarkerData: Promise<any>;

  // list of markers
  hospitalList: Array<google.maps.Marker> = [];
  fireStationList: Array<google.maps.Marker> = [];
  policeList: Array<google.maps.Marker> = [];
  tempList: Array<google.maps.Marker>;

  // markers image url
  baseUrl = 'assets/img/markers/';
  hospitalImg = this.baseUrl + 'hospital.png';
  fireStationImg = this.baseUrl + 'fire_station.png';
  policeImg = this.baseUrl + 'police.png';
  tempMarkerImg: string;

  risksSubscribe: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    private ngZone: NgZone,
    private tokenStorageService: TokenStorageService,
    // private _userSessionService: UserSessionService,
    // private httpService: HttpService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    const context = this;
    const mapProp = {
      center: new google.maps.LatLng(this.lat, this.lng),
      zoom: 15,
      mapTypeControlOptions: {
        mapTypeIds: [
            google.maps.MapTypeId.ROADMAP,
            google.maps.MapTypeId.SATELLITE,
            google.maps.MapTypeId.HYBRID,
            google.maps.MapTypeId.TERRAIN
        ],
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
      }
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    // Create the DIV to hold the custom buttons
    const centerControlDiv = document.createElement('div');
    centerControlDiv.className = 'buttons_container';
    this.customButtons(centerControlDiv, this);

    centerControlDiv.style.zIndex = '1';
    centerControlDiv.style.padding = '5px';
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);

    const currentCoordinates = this.tokenStorageService.getCoordinates();

    if ( currentCoordinates && currentCoordinates.lat && currentCoordinates.lng ) {

      const location = new google.maps.LatLng(currentCoordinates.lat, currentCoordinates.lng);
      context.map.panTo(location);

      const marker = new google.maps.Marker({
        map: context.map,
        position: location,
        icon:  {
          url: 'assets/img/markers/user-position.png', // url
          scaledSize: new google.maps.Size(50, 50), // size
        },
      });
    }

    this.risksSubscribe = this.userService.risks$.subscribe( (risks: RiskSchema[]) => {
      if ( risks.length > 0 ) {
        this.loadUserRisks(risks);
      }
    });
  }

  ngOnDestroy(): void {
    if ( this.risksSubscribe ) {
      this.risksSubscribe.unsubscribe();
    }
  }

  loadUserRisks(risks: RiskSchema[]) {

    risks.map( risk => {
      const riskPosition = {lat: risk.coordinate.geometry.coordinates[1], lng: risk.coordinate.geometry.coordinates[0]};

      const marker = new google.maps.Marker({
        position: riskPosition,
        icon: {
          url: environment.container.url + risk.riskAlertList[risk.riskAlertList.length - 1].marker,
          scaledSize: new google.maps.Size(50, 70), // size
        },
        map: this.map,
        title: risk.description
        });

      marker.addListener('click', (event) => {
        const html = '<b>Descripción:</b> ' + risk.description + '<br/>';
        this.infowindow.setContent(html);
        this.infowindow.setPosition(event.latLng);
        this.infowindow.setOptions({pixelOffset: new google.maps.Size(0, -34)});
        this.infowindow.open(this.map);
      });

      if ( risk.riskPolygon ) {
        this.map.data.addGeoJson(risk.riskPolygon);
      }
    });

  }

  /**
   * add maker in map
   */
  createMarker(place) {
    const marker = new google.maps.Marker({
      map: this.map,
      position: place.geometry.location,
      icon:  {
        url: this.tempMarkerImg,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(25, 25)
      }
    });

    const infowindow = new google.maps.InfoWindow();

    google.maps.event.addListener(marker, 'click', () => {
      this.ngZone.run(() => {
        infowindow.setContent(place.name);
        infowindow.open(this.map, marker);
      });
    });

    this.tempList.push(marker);
  }

  /**
   * execute Google Maps Places callback
   */
  callback(results, status) {
    let place;
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      for (let i = 0; i < results.length; i++) {
        place = results[i];
        this.createMarker(place);
      }
    }
  }

  /**
   * show Google Maps places
   */
  showPlaces(searchString: string, type: string) {

    const request = {
      bounds: this.map.getBounds(),
      keyword: searchString,
      types: [type]
    };

    // select markers list
    switch (type) {
      case 'hospital':
        this.tempList = this.hospitalList;
        this.tempMarkerImg = this.hospitalImg;
        break;
      case 'fire_station':
        this.tempList = this.fireStationList;
        this.tempMarkerImg = this.fireStationImg;
        break;
      case 'police':
        this.tempList = this.policeList;
        this.tempMarkerImg = this.policeImg;
        break;
      default:
        console.error('invalid place type');
        return;
    }

    // clear actual marker
    if (this.tempList) {
      this.deleteMarkers(this.tempList);
    }

    const service = new google.maps.places.PlacesService(this.map);
    service.nearbySearch(request, (results, status) => {
      this.callback(results, status);
    });
  }

  /**
   * Sets the map on all markers in the array.
   */
  setMapOnAll(map, list) {
    for (var i = 0; i < list.length; i++) {
      list[i].setMap(map);
    }
  }

  /**
   * Shows any markers currently in the array.
   */
  showMarkers(list) {
    this.setMapOnAll(this.map, list);
  }

  /**
   * Removes the markers from the map, but keeps them in the array.
   */
  clearMarkers(list) {
    this.setMapOnAll(null, list);
  }

  /**
   * Deletes all markers in the array by removing references to them.
   */
  deleteMarkers(list) {
    this.clearMarkers(list);
    list = [];
  }

  /**
   * delete all map markers
   */
  deleteAllMarkers() {
    this.deleteMarkers(this.hospitalList);
    this.deleteMarkers(this.fireStationList);
    this.deleteMarkers(this.hospitalList);
    if (this.tempList) {
      this.deleteMarkers(this.tempList);
    } else {
      this.tempList = null;
    }
  }

  /**
   * Draw custom buttons
   */
  customButtons(controlDiv, context) {

    // adding markers buttons
    const hospitalBtn = context.buildButton('Hospitales', 'hospital', 'hospital', 'fa-medkit', 'btn-primary', context);
    controlDiv.appendChild(hospitalBtn);
    const fireStationBtn = context.buildButton('Estaciones de bomberos', '', 'fire_station', 'fa-fire', 'btn-danger', context);
    controlDiv.appendChild(fireStationBtn);
    const policeBtn = context.buildButton('Estaciones de policía', 'policía', 'police', 'fa-user', 'btn-warning', context);
    controlDiv.appendChild(policeBtn);

    // adding clear button
    const clearBtn = document.createElement('div');
    clearBtn.className = 'dim btn btn-default';
    clearBtn.style.margin = '0px 2px';
    clearBtn.title = 'limpiar';

    const controlText = document.createElement('i');
    controlText.className = 'fa fa-times';
    clearBtn.appendChild(controlText);

    clearBtn.addEventListener('click',
      () => {
        context.deleteAllMarkers();
      }
    );
    controlDiv.appendChild(clearBtn);

  }

  /**
   * build custom button
   */
  buildButton(title: string, searchString: string, type: string, icon: string, cssClass: string, context: any) {

    const controlUI = document.createElement('div');
    controlUI.className = 'dim btn ' + cssClass;
    controlUI.style.margin = '0px 2px';
    controlUI.title = title;

    const controlText = document.createElement('i');
    controlText.className = 'fa ' + icon;
    controlUI.appendChild(controlText);

    controlUI.addEventListener('click',
      () => {
        context.showPlaces(searchString, type);
      }
    );

    return controlUI;
  }


}
