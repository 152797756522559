import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule, BsModalRef } from 'ngx-bootstrap';
import { ProgressBarModule } from 'primeng/progressbar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';

import { ShareRoutingModule } from './share-routing.module';

//Angular Material
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';

import { TopNavBarComponent } from './_components/top-nav-bar/top-nav-bar.component';
import { BreadcrumbComponent } from './_components/breadcrumb/breadcrumb.component';
import { VideosComponent } from './_components/right-side-panel/videos/videos.component';
import { FilterComponent } from './_components/right-side-panel/filter/filter.component';
import { SearchComponent } from './_components/right-side-panel/search/search.component';
import { LayersComponent } from './_components/right-side-panel/layers/layers.component';
import { LeftSideMenuComponent } from './_components/left-side-menu/left-side-menu.component';
import { ChartsComponent } from './_components/right-side-panel/charts/charts.component';
import { RisksChartsComponent } from './_components/right-side-panel/charts/_components/risks-charts/risks-charts.component';
import { EventsChartsComponent } from './_components/right-side-panel/charts/_components/events-charts/events-charts.component';
import { ReportsComponent } from './_components/right-side-panel/reports/reports.component';
import { MapEmployeeComponent } from '../index/_modules/home/_components/dashboard-employee/_components/map-employee/map-employee.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { TreeTableModule } from 'primeng/treetable';
import {ButtonModule} from 'primeng/button';
import { ModalLogTaskComponent } from '../index/_modules/home/_components/dashboard-employee/_components/modals/modal-log-task/modal-log-task.component';
import { ModalMapEmployeeComponent } from '../index/_modules/home/_components/dashboard-employee/_components/modals/modal-map-employee/modal-map-employee.component';
import { ModalHealthConditionComponent } from '../index/_modules/home/_components/dashboard-employee/_components/modals/modal-health-condition/modal-health-condition.component';
import { ModalSelfDiagnosticComponent } from '../index/_modules/home/_components/dashboard-employee/_components/modals/modal-self-diagnostic/modal-self-diagnostic.component';
import { ModalMeetsEmployeeComponent } from '../index/_modules/home/_components/dashboard-employee/_components/modals/modal-meets-employee/modal-meets-employee.component';
import {CalendarModule} from 'primeng/calendar';
import { ModalImageRecognitionComponent } from '../index/_modules/home/_components/dashboard-employee/_components/modal-image-recognition/modal-image-recognition.component';
import { AddUserToOrganizationComponent } from '../index/_modules/admin/_components/employee/_components/modals/add-user-to-organization/add-user-to-organization.component';
import { UserTaskManagementComponent } from '../index/_modules/admin/_components/user-task-management/user-task-management.component';
import { SelfDiagnosticStatusComponent } from '../index/_modules/admin/_components/employee/_components/modals/self-diagnostic-status/self-diagnostic-status.component';
import { ModalSearchAutodiagnosticComponent } from '../index/_modules/home/_components/dashboard-employee/_components/modals/modal-search-autodiagnostic/modal-search-autodiagnostic.component';


@NgModule({
  declarations: [
    LeftSideMenuComponent,
    TopNavBarComponent,
    BreadcrumbComponent,
    VideosComponent,
    FilterComponent,
    SearchComponent,
    LayersComponent,
    ChartsComponent,
    RisksChartsComponent,
    EventsChartsComponent,
    ReportsComponent,
    MapEmployeeComponent,
    ModalLogTaskComponent,
    ModalMapEmployeeComponent,
    ModalHealthConditionComponent,
    ModalSelfDiagnosticComponent,
    ModalImageRecognitionComponent,
    ModalMeetsEmployeeComponent,
    AddUserToOrganizationComponent,
    UserTaskManagementComponent,
    SelfDiagnosticStatusComponent,
    ModalSearchAutodiagnosticComponent
  ],
  exports: [
    LeftSideMenuComponent,
    TopNavBarComponent,
    BreadcrumbComponent,
    VideosComponent,
    FilterComponent,
    SearchComponent,
    LayersComponent,
    ChartsComponent,
    RisksChartsComponent,
    EventsChartsComponent,
    ReportsComponent,
    MapEmployeeComponent,
    ModalLogTaskComponent,
    ModalMapEmployeeComponent,
    ModalHealthConditionComponent,
    ModalSelfDiagnosticComponent,
    ModalMeetsEmployeeComponent,
    ModalImageRecognitionComponent,
    AddUserToOrganizationComponent,
    UserTaskManagementComponent,
    SelfDiagnosticStatusComponent,
    ModalSearchAutodiagnosticComponent
  ],
  entryComponents: [
    MapEmployeeComponent,
    ModalLogTaskComponent,
    ModalMapEmployeeComponent,
    ModalHealthConditionComponent,
    ModalSelfDiagnosticComponent,
    ModalMeetsEmployeeComponent,
    ModalImageRecognitionComponent,
    AddUserToOrganizationComponent,
    UserTaskManagementComponent,
    SelfDiagnosticStatusComponent,
    ModalSearchAutodiagnosticComponent
  ],
  imports: [
    NgbModule,
    ShareRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    ProgressBarModule,
    CheckboxModule,
    DropdownModule,
    ListboxModule,
    LeafletModule,
    LeafletDrawModule,
    TreeTableModule,
    ButtonModule,
    CalendarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    MatFormFieldModule
  ], 
  providers: [
    BsModalRef
],
})
export class ShareModule { }
