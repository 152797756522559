import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication/authentication.service';

import { DeviceSchema } from '../_schemas/device-schema';

@Injectable()
export class IotService {


    private devicesSchema: DeviceSchema[] = [];
    private devices = new BehaviorSubject<DeviceSchema[]>(this.devicesSchema);
    public devices$ = this.devices.asObservable();


    constructor(
        private httpService: HttpService,
        private authService: AuthenticationService
    ) {
        this.getDevices();
    }

    getDevices() {
        this.authService.isAuthorized().subscribe( isAuthorize => {
            if ( isAuthorize ) {
                this.httpService.get(environment.endpoints.userDevices).subscribe( (res) => {
                    this.devicesSchema = res.data;
                    this.devices.next(this.devicesSchema);
                });
            }
        });
    }
}
