import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() {
  }

  downloadFile(blob: Blob, name: string) {
    const url = window.URL.createObjectURL(blob);
    const linkDownload = document.createElement('a');
    linkDownload.download = name;
    linkDownload.href = url;
    linkDownload.click();
    linkDownload.remove();
  }

  /**
   * check if file is an image
   */
  isImage(file: any): boolean {
    return (file.filename.indexOf('.jpeg') > -1 || file.filename.indexOf('.jpg') > -1 || file.filename.indexOf('.png') > -1
    || file.filename.indexOf('.JPEG') > -1 || file.filename.indexOf('.JPG') > -1 || file.filename.indexOf('.PNG') > -1);
  }

  /**
   * check if file is an audio
   */
  isAudio(file: any): boolean {
    return (file.filename.indexOf('.mp3') > -1 || file.filename.indexOf('.MP3') > -1);
  }

  /**
   * check if file is an video
   */
  isVideo(file: any): boolean {
    return (file.filename.indexOf('.mp4') > -1 || file.filename.indexOf('.MP4') > -1);
  }

  /**
   * get image source url
   */
  getImageSrc(fileData: any, size: string): string {
    if (fileData && fileData.filename && fileData.path) {

      const url = fileData.path + fileData.filename;
      const serviceUrl = environment.endpoints.imagePreview.replace('%size%', size);
      const accessToken = localStorage.getItem('accessToken');
      const accessTokenText = `access_token=${accessToken}&`;
      return serviceUrl + '?' + (accessToken ? accessTokenText : '') + `url=${url}`;
    } else {
      return '';
    }
  }

  /**
   * get file source url
   */
  getFileSrc(fileData: any): string {
    if (fileData && fileData.filename && fileData.path) {

      const url = fileData.path + fileData.filename;
      const serviceUrl = environment.endpoints.filePreview;
      const accessToken = localStorage.getItem('accessToken');
      const accessTokenText = `access_token=${accessToken}&`;

      return serviceUrl + '?' + (accessToken ? accessTokenText : '') + `url=${url}`;
    } else {
      return '';
    }
  }

}
