import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap';
import { environment } from 'src/environments/environment';
import { EmployeeSchema } from 'src/app/_schemas/employee-schema';
import { HttpService } from 'src/app/_services/http.service';

@Component({
  selector: 'app-modal-meets-employee',
  templateUrl: './modal-meets-employee.component.html',
  styleUrls: ['./modal-meets-employee.component.scss']
})
export class ModalMeetsEmployeeComponent implements OnInit {

  @Input()
  employee: EmployeeSchema;
  showGraph = false;
  showLoading = true;
  showMessageNoData = false;
  filterDate = new Date();
  maxDate = new Date();
  es;
  chartColors = ['rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgba(255, 99, 132)',
  'rgb(231,233,237)'];

  //charts js variables
  canvas;
  ctx;
  myChart: Chart = null;

  constructor(public bsModalRef: BsModalRef,
    private httpService: HttpService) { }

  ngOnInit() {
    this.es = {
      firstDayOfWeek: 1,
      dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],
      dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
      dayNamesMin: [ "D","L","M","X","J","V","S" ],
      monthNames: [ "enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre" ],
      monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ],
      today: 'Hoy',
      clear: 'Borrar'
  }

    this.search();
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  timeFormat = 'MM/DD/YYYY hh:mm:ss';

  generateLabels(min, max) {
    let list = [];
    for (let index = min; index < max; index++) {
      list.push(this.newDateAddingHours(index));
      
    }
    return list;
  }

  newDateAddingHours(hours) {
    return moment(this.filterDate).startOf('day').add(hours, 'hours').toDate();
  }
  search() {
    
    //string format yyyy-mm-dd
    const date = moment(this.filterDate).get('year').toString() + '-' + (moment(this.filterDate).get('month') + 1 ).toString() + '-' + moment(this.filterDate).get('date').toString();
 
    
    this.showLoading = true;
    if(this.myChart) {
      this.myChart.destroy();
    }
    const url = environment.endpoints.getMeets
    .replace('{userAccountId}', this.employee.userId.toString())
    .replace('{date}', date);
    let colours = Array.from(this.chartColors);

    let minHour = 24;
    let maxHour = 0;
    this.httpService.get(url)
      .subscribe( (response) => {
        
        this.showLoading = false;
         const data = response['data'];
         if(!data || data.length === 0) {
           this.showMessageNoData = true;
           this.myChart.destroy();
           return;
         }
         let dataset = [];
         let point: any = {};
         let set: any =  {};
         data.forEach(obj => {
          set = {};
          set.label = obj.name;
          set.fill = false;
          set.pointRadius = 5;
          set.pointHoverRadius = 5;
          const random = Math.floor(Math.random() * colours.length);
          set.backgroundColor = colours[random];
          colours.splice(random, 1);
          set.backgroundColor = set.backgroundColor.replace(')', ',0.6)');
          set.borderColor = set.backgroundColor.replace('0.6)', '1)');
          set.data = [];
            obj.meets.forEach(date => {
              if(moment(date).hour() < minHour) {
                minHour = moment(date).hour();
              }
              if(moment(date).hour() > maxHour) {
                maxHour = moment(date).hour();
              }
              point = {};
              point.x = moment(date);
              point.y = 1
              set.data.push(point);
            });
          dataset.push(set);
          
        
         });
         
        maxHour = maxHour + 2,
        console.log('max hour', maxHour);
         this.showGraph = true;
         this.showMessageNoData = false;
         setTimeout(() => {
           
        this.canvas = document.getElementById("meets");
        this.ctx = this.canvas.getContext("2d");
         this.myChart = new Chart(this.ctx, {
          type: 'line',
          data: {
            labels: this.generateLabels(minHour, maxHour)
            ,
            datasets: dataset
          },
          options: {
            legend: {
              position: 'top'
            },
            showLines: false,
            scales: {
              xAxes: [{
                gridLines: {
                  display: true
                },
                type: 'time',
                time: {
                  parser: this.timeFormat,
                  tooltipFormat: 'MM/DD/YYYY hh:mm:ss'
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Hora'
                }
              }],
              yAxes: [{
                gridLines: {
                  display: true
                },
                ticks: {
                  display: false,
                  tickMarkLength: 1
                },
                scaleLabel: {
                  display: false,
                }
              }]
            },
          }
    
        });
         }, 1000);

      });
    
  }



    

}
