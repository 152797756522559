import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { YoutubeRequestSchema } from 'src/app/_schemas/youtube-request-schema';
import { OrganizationSchema } from 'src/app/_schemas/organization-schema';
import { UserService } from 'src/app/_services/user.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})



export class VideosComponent implements OnInit, OnDestroy {

  organizationSubscribe: Subscription;

  playlistData: YoutubeRequestSchema;
  organizationPlaylistData: YoutubeRequestSchema;
  organization: OrganizationSchema;

  constructor(
    private domSanitizer: DomSanitizer,
    private userService: UserService
  ) {
    this.playlistData = new YoutubeRequestSchema();
    this.playlistData.pageInfo = { totalResults: 0, resultsPerPage: 0 };

    this.organizationPlaylistData = new YoutubeRequestSchema();
    this.organizationPlaylistData.pageInfo = { totalResults: 0, resultsPerPage: 0 };
  }

  ngOnInit() {

    const context = this;

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
          context.playlistData = JSON.parse(this.response);

          // generate sanitized url for each video
          context.playlistData.items.forEach((element: any) => {
            element.sanitizedUrl = context.getEmbedVideoUrl(element.snippet.resourceId.videoId);
          });
       }
    };
    xhttp.open('GET', environment.youtube.playlistItems, true);
    xhttp.send();

    this.organizationSubscribe = this.userService.organization$.subscribe(organization => {
      if (organization) {
        this.organization = organization;
        if (this.organization.youtubePlayListId) {
          const params = '?part=snippet&youtubePlayListId=' + this.organization.youtubePlayListId
          + '&maxResults=25&key=AIzaSyD2wmsuepT4YdkHJ60-5_ahqivnZSvvobI';

          const orgXhttp = new XMLHttpRequest();
          orgXhttp.onreadystatechange = function() {
              if (this.readyState === 4 && this.status === 200) {
                context.organizationPlaylistData = JSON.parse(this.response);

                // generate sanitized url for each video
                context.organizationPlaylistData.items.forEach((element: any) => {
                  element.sanitizedUrl = context.getEmbedVideoUrl(element.snippet.resourceId.videoId);
                });
            }
          };
          orgXhttp.open('GET', environment.youtube.playlistItems + params, true);
          orgXhttp.send();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if ( this.organizationSubscribe ) {
      this.organizationSubscribe.unsubscribe();
    }
  }

  /**
   * get iframe sanitized url
   */
  getEmbedVideoUrl(videoId: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(environment.youtube.embed + videoId);
  }

}


