export class UserSchema {
    user: UserInfo;
    userJson: string;
    profileName: string;
    environment: string;
    roles: string[];

    constructor(data?: any) {
        if (data === undefined) {
            this.user = {
                id: null,
                username: null,
                password: null,
                credentialsExpired: null,
                accountInfo: {
                    firstName: null,
                    lastName: null,
                    fullName: null,
                    email: null
                },
                roleUser: null
            };
        }
    }
}

export class UserInfo {
    id: number;
    username: string;
    password: string;
    credentialsExpired: boolean;
    accountInfo: {
        firstName?: string;
        lastName?: string;
        fullName?: string;
        email: string
    };
    roleUser: {
        id: number,
        name: string,
        active: string,
    }[];
}

export class UserSignup {

    // MANDATORY
    id: any;
    username: string;
    password: string;
    credentialsExpired: boolean ;
    accountInfo: any ;
    group: string;
    role: string;

    constructor() {
        this.group = 'SYS_USER_RISK_ST';
        this.role = 'RL_USER_RISK_ST';
        this.credentialsExpired = false;
    }

    toJS() {
        let data =  {} ;
        data['id'] = this.id !== undefined ? this.id : null;
        data['username'] = this.username !== undefined ? this.username : null;
        data['password'] = this.password !== undefined ? this.password : null;
        data['credentialsExpired'] = this.credentialsExpired  ;
        data['accountInfo'] = this.accountInfo !== undefined ? this.accountInfo : null;
        data['group'] = this.group ;
        data['role'] = this.role ;
        return data;
    }
}


