import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of } from 'rxjs';

@Injectable()
export class TokenStorageService {

    /**
     * Get access token
     * @returns Observable<string>
     */
    public getAccessToken(): Observable<string> {
        const token: string = localStorage.getItem('accessToken');
        return of(token);
    }

    /**
     * Get refresh token
     * @returns Observable<string>
     */
    public getRefreshToken(): Observable<string> {
        const token: string = localStorage.getItem('refreshToken');
        return of(token);
    }

    /**
     * Set access token
     * @returns TokenStorageService
     */
    public setAccessToken(token: string): TokenStorageService {
        localStorage.setItem('accessToken', token);
        return this;
    }

    /**
     * Set refresh token
     * @returns TokenStorageService
     */
    public setRefreshToken(token: string): TokenStorageService {
        localStorage.setItem('refreshToken', token);
        return this;
    }

    /**
     * Remove tokens
     */
    public clear() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }

    /**
     * Get access token (not observable)
     */
    public getAccessTokenString(): string {
        return localStorage.getItem('accessToken');
    }

    /**
     * Get refresh token (not observable)
     */
    public getRefreshTokenString(): string {
        return localStorage.getItem('refreshToken');
    }

    /**
     * Get coordinates (not observable)
     */
    public getCoordinates() {
        return {lat: localStorage.getItem('lat'), lng: localStorage.getItem('lng')};
    }

    /**
     * Set coordinates
     */
    public setCoordinates(coordinates: {lat: string, lng: string}) {
        localStorage.setItem('lat', coordinates.lat);
        localStorage.setItem('lng', coordinates.lng);
        return this;
    }
}
