import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/_services/http.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/_services/user.service';
import { Subscription } from 'rxjs';
import { FileService } from 'src/app/_services/file.service';
import { OrganizationSchema } from 'src/app/_schemas/organization-schema';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var jQuery: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

  risksSubscribe: Subscription;
  organizationSubscribe: Subscription;

  // departments select
  departments;
  selectedDepartament: any;

  // municipalities listBox
  municipalities;
  selectedMunicipality: any;

  risksList;
  selectedRisks = [];

  // Progress bar
  showProgressBar: boolean;

  bsModalRef: BsModalRef;
  previewFileURL;

  organization: OrganizationSchema;
  reportFileLoadedCount: number;

  constructor(
    private httpService: HttpService,
    private userService: UserService,
    public fileService: FileService,
    private sanitizer: DomSanitizer,
    private modalService: BsModalService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.showProgressBar = false;
    this.loadDepartments();

    this.organizationSubscribe = this.userService.organization$.subscribe( organization => {
      if ( organization ) {
        this.organization = organization;
      }
    });
  }

  ngOnDestroy(): void {
    if ( this.risksSubscribe ) {
      this.risksSubscribe.unsubscribe();
    }
    if ( this.organizationSubscribe ) {
      this.organizationSubscribe.unsubscribe();
    }
  }

  /**
   * load departments
   */
  loadDepartments() {
    this.httpService.get(environment.endpoints.departments).subscribe((res: any) => {
      // set department values
      this.departments = [
        {label: 'Seleccione un departamento', value: null},
        {label: 'Todos', value: 'all'}
      ];
      res.forEach(department => {
          this.departments.push({label: department.name, value: department.locationId});
      });
    });
  }

  /**
   * load municipalities
   */
  loadMunicipalities() {

    this.selectedMunicipality = '';
    this.municipalities = [];
    this.risksList = [];
    this.selectedRisks = [];

    if ( ! this.selectedDepartament) {
      return;
    }
    if ( this.selectedDepartament === 'all') {
      this.toastr.warning('Al seleccionar todos los departamentos, el informe podrá demorar mas tiempo en generarse');
      this.loadRisksList();
      return;
    }

    const url = environment.endpoints.municipalities.replace('%departmentId%', this.selectedDepartament);
    this.httpService.get(url).subscribe((res: any) => {
      this.municipalities = [
        {label: 'Seleccione un municipio', value: null},
        {label: 'Todos', value: 'all'}
      ];
      res.forEach(municipality => {
        this.municipalities.push({label: municipality.name, value: municipality.locationId});
      });
    });
  }

  loadRisksList() {

    this.risksList = [];
    this.selectedRisks = [];

    if (!this.selectedDepartament) {
      return;
    }
    if ( this.selectedDepartament !== 'all' ) {
      if ( !this.selectedMunicipality) {
        return;
      }
    }

    this.showProgressBar = true;

    this.risksSubscribe = this.userService.risks$.subscribe(risks => {

      if ( risks.length > 0 ) {
        risks.map((risk, index) => {

         let temporal = risk.description.toUpperCase();
         let verificacion=temporal.includes("TEST");
         let verificacion2=temporal.includes("PRUEBA");
         let verificacion3=temporal.includes("PRUEBAS");

         if((verificacion==false && verificacion2==false && verificacion3==false) && risk.description!="" && risk.riskId!=601 && risk.description!="RTW" && risk.description!="hsja" && risk.description!="hdjz" && risk.description!="haha" && risk.riskId!=681 && risk.description!="asdf"){
          console.log(temporal+verificacion+risk.riskId)
          const riskOption = {
            label: risk.description,
            value: risk.riskId
          };
          if ( this.selectedDepartament === 'all' ) {
            this.risksList.push(riskOption);
          } else {
            if (risk.department.locationId === this.selectedDepartament ) {
              if ( this.selectedMunicipality === 'all' ) {
                this.risksList.push(riskOption);
              } else {
                if (risk.town.locationId === this.selectedMunicipality ) {
                  this.risksList.push(riskOption);
                }
              }
            }
          }
          if ( index >= risks.length - 1 ) {
            this.showProgressBar = false;
          }
        }


        });
      }
    });
  }

  /**
   * download Jasper report
   */
  downloadReportFile(fileType: string) {

    if (!this.selectedDepartament) {
      this.toastr.warning('Seleccione todos los filtros');
      return;
    }
    if ( this.selectedDepartament !== 'all' && !this.selectedMunicipality) {
      this.toastr.warning('Seleccione todos los filtros');
      return;
    }

    if ( this.selectedRisks.length === 0 ) {
      this.toastr.warning('Seleccione al menos un riesgo de la lista');
      return;
    }

    this.showProgressBar = true;
    let url = environment.endpoints.downloadReport
    + '?access_token=' + localStorage.getItem('accessToken')
    + '&backend_url=' + environment.endpoints.BACK_END_URL
    + '&frontend_url=' + environment.endpoints.FRONT_END_URL
    + '&reportName=' + 'RisksReport'
    + '&organizacion_id=' + this.organization.organizationId.toString()
    + '&riesgo_id_list=' + this.selectedRisks;

    if ( this.selectedRisks.length === 1) {
      url += ',';
    }
    switch (fileType) {
      case 'pdf':
        url += '&outputType=pdf';
        break;
      case 'xlsx':
        url += '&outputType=xlsx&showTitle=false&showPageFooter=false';
        break;
    }

    // get blob file
    this.httpService.getBlob( url, null).subscribe( (data: Blob) => {
      this.fileService.downloadFile(data, 'Riesgos_' + Date.now().toString() + '.' + fileType);
      this.showProgressBar = false;
    });
  }

  /* preview Jasper report*/
  previewFile(template: TemplateRef<any>): void {

    if (!this.selectedDepartament) {
      this.toastr.warning('Seleccione todos los filtros');
      return;
    }
    if ( this.selectedDepartament !== 'all' && !this.selectedMunicipality) {
      this.toastr.warning('Seleccione todos los filtros');
      return;
    }
    if ( this.selectedRisks.length === 0 ) {
      this.toastr.warning('Seleccione al menos un riesgo de la lista');
      return;
    }

    let url = environment.endpoints.downloadReport
    + '?access_token=' + localStorage.getItem('accessToken')
    + '&backend_url=' + environment.endpoints.BACK_END_URL
    + '&frontend_url=' + environment.endpoints.FRONT_END_URL
    + '&reportName=' + 'RisksReport'
    + '&organizacion_id=' + this.organization.organizationId.toString()
    + '&riesgo_id_list=' +  this.selectedRisks;
    if ( this.selectedRisks.length === 1) {
      url += ',&outputType=pdf#toolbar=0';
    } else {
      url += '&outputType=pdf#toolbar=0';
    }

    this.reportFileLoadedCount = 0;
    jQuery('#progressPreviewPdf').css('display', 'block');

    this.previewFileURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  reportFileLoaded() {
    this.reportFileLoadedCount ++;

    if ( this.reportFileLoadedCount === 2 ) {
      jQuery('#progressPreviewPdf').css('display', 'none');
    }
  }

}
