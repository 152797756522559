import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() modalDataInput: any;

  modalData: {
    message: string;
    okBtnText: string;
  };

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.modalData = this.modalDataInput;
  }

  /**
   * alert modal
   */
  okBtnEvent() {
    this.bsModalRef.hide();
  }

}
