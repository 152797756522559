import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { RiskRecommendationList } from 'src/app/_schemas/risk-schema';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FileUploader } from 'ng2-file-upload';
import { HttpService } from 'src/app/_services/http.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-add-risk-recommendation',
  templateUrl: './add-risk-recommendation.component.html',
  styleUrls: ['./add-risk-recommendation.component.scss']
})
export class AddRiskRecommendationComponent implements OnInit {
  @Output() reloadRiskEvent = new EventEmitter<RiskRecommendationList>();

  riskId: number;
  description: string;

  showProgressBar: boolean;

  // file uploader variables
  public uploader: FileUploader;
  public hasBaseDropZoneOver;
  uploaderResponse: string;

  constructor(
    public bsModalRef: BsModalRef,
    private cd: ChangeDetectorRef,
    private httpService: HttpService,
    private toastr: ToastrService,
    public uiService: UiService // used in html
  ) {
    this.uploader = new FileUploader({
      url: environment.endpoints.BACK_END_URL,
      allowedMimeType: ['image/png', 'image/jpeg', 'audio/mp3', 'audio/mpeg', 'video/mp4', 'video/mpeg'],
      maxFileSize: 10 * 1024 * 1024,
      queueLimit: 5,
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise( (resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });

    this.hasBaseDropZoneOver = false;
    this.uploaderResponse = '';
    this.uploader.response.subscribe( res => this.uploaderResponse = res );
  }

  ngOnInit() {
    this.showProgressBar = false;
  }

  afterModelLoad(): void {
    this.cd.detectChanges();
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  onSubmit() {
    this.showProgressBar = true;
    this.cd.detectChanges();

    const formData = new FormData();
    formData.append('riskId', this.riskId.toString());
    formData.append('description', this.description);

    for (let i = 0; i < this.uploader.queue.length; i++) {
      const element = this.uploader.queue[i];
      formData.append('files', element._file);
    }

    this.httpService.saveFD(environment.endpoints.addRiskRecommendation, formData).subscribe( (res) => {
      this.toastr.success('Recomendación guardada correctamente');
      this.bsModalRef.hide();
      this.reloadRiskEvent.emit(res.data);
      this.showProgressBar = false;
    },
    (error) => {
      this.toastr.error('Error del servidor');
      this.showProgressBar = false;
      this.cd.detectChanges();
    });

  }

  // detect drag & drop mouse cursor
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
    this.cd.detectChanges();
  }

  // patches for redraw modal data
  onChange(e: Event) {
    this.cd.detectChanges();
  }
  clearQueue() {
    this.uploader.clearQueue();
    this.cd.detectChanges();
  }
  removeItem(item) {
    item.remove();
    this.cd.detectChanges();
  }
}
