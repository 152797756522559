import { Component, OnInit, ViewChild } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { HttpService } from 'src/app/_services/http.service';
import { environment } from 'src/environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalHealthConditionComponent } from '../modal-health-condition/modal-health-condition.component';
import { ModalSelfDiagnosticComponent } from '../modal-self-diagnostic/modal-self-diagnostic.component';
import { MatSort, MatTableDataSource } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { EmployeeSchema } from 'src/app/_schemas/employee-schema';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-search-autodiagnostic',
  templateUrl: './modal-search-autodiagnostic.component.html',
  styleUrls: ['./modal-search-autodiagnostic.component.scss']
})
export class ModalSearchAutodiagnosticComponent implements OnInit {

  employees: EmployeeSchema[];
  employeesSize: any;
  tableIsLoading: boolean;
  initialFilterDate = new Date();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns: string[] = ['email','fullName','cellPhoneNumber'];
  dataSource: MatTableDataSource<EmployeeSchema>;

  taskTree: TreeNode[];
  user: any;
  constructor(private httpService: HttpService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.getEmployeesInfo();
    this.buildNodes();
  }

  getEmployeesInfo() {
    this.tableIsLoading = true;

    const datef = moment(this.initialFilterDate).get('year').toString()
              + '-' + (moment(this.initialFilterDate).get('month') + 1 ).toString()
              + '-' + moment(this.initialFilterDate).get('date').toString();

    let url = environment.endpoints.wellness.getEmployeesWithAutodiag.replace('{initialDate}', datef);
    url = url.replace('{endDate}', datef);

    this.httpService.get(url)
        .subscribe( (res: EmployeeSchema[]) => {
          this.employees = res['data'];
          this.employeesSize = this.employees.length;
          this.dataSource = new MatTableDataSource(this.employees);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.tableIsLoading = false;
        });
  }


  buildNodes() {
    this.httpService.get(environment.endpoints.getTaskLogByUser + this.user).subscribe(res => {
      let tasks = res.data;
      if (tasks.length > 0) {
        const taskNodes = [];
        tasks.map((task, index) => {
          let taskChildren = [];

          const tempTask = {
            text: task.text,
            executedDate: task.executedDate,
            status: task.status,
            context: task.context,
            contextValue: task.contextValue
          };
          taskNodes.push({ data: tempTask, children: taskChildren });
        });
        this.taskTree = taskNodes;
      }
    });
  }


  closeModal() {
    this.bsModalRef.hide();
    this.modalService._hideModal(1);
  }

  showTaskDetail(context, contextValue) {
    let initialState = null;
    switch (context) {
      case 'HealthCondition':
        //open caracterizacion
        initialState = {
          healthConditionId: contextValue,
          userId: this.user
        };
        this.bsModalRef = this.modalService.show(ModalHealthConditionComponent, {
          class: 'modal-lg',
          backdrop: 'static',
          initialState,
        });

        return;
      case 'SelfDiagnostic':
        //open autodiagnostico
        initialState = {
          selfDiagnosticId: contextValue,
        };
        this.bsModalRef = this.modalService.show(ModalSelfDiagnosticComponent, {
          class: 'modal-lg',
          backdrop: 'static',
          initialState,
        });
        return;
      default:
        break;
    }

    const onHideModalSubscribe = this.modalService.onHide.subscribe(() => {
      onHideModalSubscribe.unsubscribe();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
