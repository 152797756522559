import { Injectable } from '@angular/core';
import { UserSchema } from 'src/app/_schemas/user-schema';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication/authentication.service';
import { MenuSchema } from '../_schemas/menu-schema';
import { RiskSchema, RiskType, Coordinate } from '../_schemas/risk-schema';
import { OrganizationSchema } from '../_schemas/organization-schema';
import { FirebasePushNotificationService } from './firebase-push-notification.service';
import { NotificationService } from './notification.service';
import { EmployeeSchema } from '../_schemas/employee-schema';
import { filter } from 'rxjs-compat/operator/filter';

@Injectable()
export class UserService {

    private userSchema: UserSchema = null;
    private user = new BehaviorSubject<UserSchema>(this.userSchema);
    public user$ = this.user.asObservable();

    private employeeSchema: EmployeeSchema = null;
    private employee = new BehaviorSubject<EmployeeSchema>(this.employeeSchema);
    public employee$ = this.employee.asObservable();

    private menuSchema: MenuSchema = null;
    private menu = new BehaviorSubject<MenuSchema>(this.menuSchema);
    public menu$ = this.menu.asObservable();

    private risksSchema: RiskSchema[] = [];
    private risks = new BehaviorSubject<RiskSchema[]>(this.risksSchema);
    public risks$ = this.risks.asObservable();

    private geoRisksSchema: Coordinate[] = [];
    private geoRisks = new BehaviorSubject<Coordinate[]>(this.geoRisksSchema);
    public geoRisks$ = this.geoRisks.asObservable();

    private selectedRisk = new BehaviorSubject<RiskSchema>(null);
    public selectedRisk$ = this.selectedRisk.asObservable();

    private organizationSchema: OrganizationSchema  = null;
    private organization = new BehaviorSubject<OrganizationSchema>(this.organizationSchema);
    public organization$ = this.organization.asObservable();

    private geoOrganizationSchema: Coordinate = null;
    private geoOrganization = new BehaviorSubject<Coordinate>(this.geoOrganizationSchema);
    public geoOrganization$ = this.geoOrganization.asObservable();

    private selectedOrganizationSchema: OrganizationSchema = null;
    private selectedOrganization = new BehaviorSubject<OrganizationSchema>(this.selectedOrganizationSchema);
    public selectedOrganization$ = this.selectedOrganization.asObservable();

    private role: string = null;
    private roleSbj = new BehaviorSubject<string>(this.role);
    public role$ = this.roleSbj.asObservable();

    private geoSevCalled : boolean = false;
    private geoSevCalledSbj = new BehaviorSubject<boolean>(this.geoSevCalled);
    public geoSevCalled$ = this.geoSevCalledSbj.asObservable();

    constructor(
        private httpService: HttpService,
        private authService: AuthenticationService,
        private fcmService: FirebasePushNotificationService,
        private notificationService: NotificationService
    ) {
        fcmService.receiveMessage().subscribe(message => {
            console.log('message', message);
        });

        this.authService.token$.subscribe(token => {
            this.getUser();
            this.getMenu();
        });
    }

    getUser() {
        this.authService.isAuthorized().subscribe( isAuthorize => {
            if ( isAuthorize ) {
                this.httpService.get(environment.endpoints.userInfo).subscribe((res) => {
                    this.userSchema = res;
                    this.user.next(this.userSchema);
                    this.loadData();
                });
            }
        });
    }

    getMenu() {
        this.authService.isAuthorized().subscribe( isAuthorize => {
            if ( isAuthorize ) {
                this.httpService.get(environment.endpoints.menuOptions).subscribe( (res) => {
                    this.menuSchema = MenuSchema.fromJS(res);
                    this.menu.next(this.menuSchema);
                });
            }
        });
    }

    setSelectedRisk(riskId: number) {
        let selectedRisk = null;
        this.risksSchema.map(riskSchema => {
            if (riskId === riskSchema.riskId) {
                selectedRisk = riskSchema;
                return;
            }
        });
        this.selectedRisk.next(selectedRisk);
    }

    setSelectedOrganization(organizationId: number) {
        if ( this.organizationSchema.organizationId === organizationId ) {
            this.selectedOrganizationSchema = this.organizationSchema;
            this.selectedOrganization.next(this.selectedOrganizationSchema);
        } else {
            this.selectedOrganization.next(null);
        }
    }

    getOrganization() {
        this.authService.isAuthorized().subscribe( isAuthorize => {
            if ( isAuthorize ) {
                this.httpService.get(environment.endpoints.userOrganization).subscribe( (res) => {
                    this.organizationSchema = res.data;
                    this.organization.next(this.organizationSchema);
                    this.registerForPush(['org_' + this.organizationSchema.organizationId.toString()]);
                });
            }
        });
    }

    getEmployee() {
        this.authService.isAuthorized().subscribe( isAuthorize => {
            if ( isAuthorize ) {
                this.httpService.get(environment.endpoints.get_employee_data).subscribe( (res) => {
                    this.employeeSchema = res.data;
                    this.employee.next(this.employeeSchema);
                });
            }
        });
    }

    getGeoOrganization() {
        this.authService.isAuthorized().subscribe( isAuthorize => {
            if ( isAuthorize ) {
                this.httpService.get(environment.endpoints.mainGeoOrganization).subscribe( (res) => {
                    this.geoOrganizationSchema = res;
                    this.geoOrganization.next(this.geoOrganizationSchema);
                });
            }
        });
    }

    // used when update organization data
    setOrganization(organization: OrganizationSchema) {

        if ( this.organizationSchema.organizationId === organization.organizationId ) {

            this.organizationSchema = organization;
            this.organization.next(this.organizationSchema);
        }

        this.selectedOrganizationSchema = organization;
        this.selectedOrganization.next(this.selectedOrganizationSchema);
    }

    loadData() {

        this.userSchema.user.roleUser.map(role => {

            switch (role.name) {
                case 'RL_ADMIN_ORG_RISK_ST':
                    this.role = role.name;
                    this.roleSbj.next(this.role);
                    this.httpService.get(environment.endpoints.organizationRisks + "?year=2022")
                    
                    .subscribe( (risksRes) => {
                        this.risksSchema = risksRes.data;
                        this.risks.next(this.risksSchema);
                        this.loadGeoRisks();
                        this.getOrganization();
                    });                    
                    return;
                case 'RL_USER_RISK_ST':
                    this.role = role.name;
                    this.roleSbj.next(this.role);
                    this.httpService.get(environment.endpoints.userRisks)
                    .subscribe( (risksRes) => {
                        this.risksSchema = risksRes.data;
                        this.risks.next(this.risksSchema);
                        this.registerForPush(['org_1', 'org_2', 'org_3', 'org_4', 'org_5']);
                        this.loadGeoRisks();
                    });                    
                    return;
                case 'RL_ADMIN_ORG_RISK_BS':
                    this.role = role.name;
                    this.roleSbj.next(this.role);
                    this.getOrganization();
                    this.getGeoOrganization();
                    return;
                case 'RL_ADMIN_RISK':
                    this.role = role.name;
                    this.roleSbj.next(this.role);
                    return;
                case 'RL_ADMIN_RISK_PR':
                    this.role = role.name;
                    this.roleSbj.next(this.role);
                    this.getOrganization();
                    this.loadGeoRisks();
                    return;
                case 'RL_USER_RISK_PR':
                    this.role = role.name;
                    this.roleSbj.next(this.role);
                    this.getEmployee();
                    return;
                case 'RL_RISK_WELLNESS':
                    this.role = role.name;
                    this.roleSbj.next(this.role);
                    this.getOrganization();
                    this.loadGeoRisks();
                    return;
                default:
                    break;
            }
        });

    }

    loadGeoRisks() {
        switch (this.role) {
            case 'RL_ADMIN_ORG_RISK_ST':
            case 'RL_ADMIN_RISK_PR':
                this.httpService.get(environment.endpoints.organizationGeoRisks + '?year=2022')
                .subscribe( (risksRes) => {
                    this.geoRisksSchema = risksRes;
                    this.geoRisks.next(this.geoRisksSchema);
                    this.geoSevCalledSbj.next(true);
                });
                break;
            case 'RL_USER_RISK_ST':
                this.httpService.get(environment.endpoints.userGeoRisks)
                .subscribe( (risksRes) => {
                    this.geoRisksSchema = risksRes;
                    this.geoRisks.next(this.geoRisksSchema);
                    this.geoSevCalledSbj.next(true);
                });
                break;
            default:
                break;
        }
    }

    updateRisks(risk: RiskSchema) {
        this.loadGeoRisks();
        let updateReady = false;
        // update risk list because edit someone
        this.risksSchema.map((riskSchema, index) => {
            if (risk.riskId === riskSchema.riskId) {
                this.risksSchema[index] = risk;
                updateReady = true;
                return;
            }
        });
        // update risk list because add a new risk
        if ( !updateReady ) {
            this.risksSchema.push(risk);
        }

        this.risks.next(this.risksSchema);
    }

    filterRisks(filterData: {valid: any, transfer: any, year : any}) {
        if ( filterData.valid === 'all' && filterData.transfer === 'all' && filterData.year === 'all') {
            this.geoRisks.next(this.geoRisksSchema);
        } else {
            let tempRisks: RiskSchema[] = this.risksSchema;

            if(filterData.valid !== 'all'){
                tempRisks = tempRisks.filter(r => r.validation === filterData.valid);
            }

            if(filterData.transfer !== 'all'){
                tempRisks = tempRisks.filter(r => (filterData.transfer && r.riskTransfer) || (filterData.transfer == null && !r.riskTransfer));
            }
            
            if(filterData.year !== 'all'){
                tempRisks = tempRisks.filter(r => (new Date(r.creationDate)).getFullYear() == filterData.year);
            }

            const tempGeoRisks: Coordinate[] = [];
            tempRisks.map(tempRisk => {

                this.geoRisksSchema.map(geoRisk => {
                    if (geoRisk.properties['riskId'] === tempRisk.riskId ) {
                        tempGeoRisks.push(geoRisk);
                    }
                });

            });
            this.geoRisks.next(tempGeoRisks);
        }
    }

    registerForPush(firebaseTopics: string[]) {
        this.fcmService.requestPermission().subscribe(
            token => {
                firebaseTopics.map(topic => {
                    const isTopicRegistered = localStorage.getItem('topic-' + topic);
                    if (! isTopicRegistered ) {
                        localStorage.setItem('topic-' + topic, token);
                        this.notificationService.registerForPush(topic, token);
                    }
                });
            },
            error => {}
        );
    }
}
