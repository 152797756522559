import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { HttpService } from 'src/app/_services/http.service';
import { environment } from 'src/environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalHealthConditionComponent } from '../modal-health-condition/modal-health-condition.component';
import { ModalSelfDiagnosticComponent } from '../modal-self-diagnostic/modal-self-diagnostic.component';

@Component({
  selector: 'app-modal-log-task',
  templateUrl: './modal-log-task.component.html',
  styleUrls: ['./modal-log-task.component.scss']
})
export class ModalLogTaskComponent implements OnInit {

  taskTree: TreeNode[];
  user: any;
  constructor(private httpService: HttpService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.buildNodes();
  }


  buildNodes() {
    this.httpService.get(environment.endpoints.getTaskLogByUser + this.user).subscribe(res => {
      let tasks = res.data;
      if (tasks.length > 0) {
        const taskNodes = [];
        tasks.map((task, index) => {
          let taskChildren = [];
          taskChildren = this.mapChildrenNodes(task);

          const tempTask = {
            text: task.text,
            executedDate: task.executedDate,
            status: task.status,
            context: task.context,
            contextValue: task.contextValue
          };
          taskNodes.push({ data: tempTask, children: taskChildren });
        });
        this.taskTree = taskNodes;
      }
    });
  }

  private mapChildrenNodes(task) {
    if (!task.taskLogChildren)
      return [];

    return this.childrenNodes(task);
  }

  private childrenNodes(task) {
    let taskChildren = [];
    let childs = [];
    task.taskLogChildren.map(child => {
      childs = this.mapChildrenNodes(child);
      const tempChild = {
        text: child.text,
        executedDate: child.executedDate,
        status: child.status,
        context: child.context,
        contextValue: child.contextValue
      };

      taskChildren.push({ data: tempChild, children: childs });
    });
    return taskChildren;
  }

  closeModal() {
    this.bsModalRef.hide();
    this.modalService._hideModal(1);
  }

  showTaskDetail(context, contextValue) {
    let initialState = null;
    switch (context) {
      case 'HealthCondition':
        //open caracterizacion
        initialState = {
          healthConditionId: contextValue,
          userId: this.user
        };
        this.bsModalRef = this.modalService.show(ModalHealthConditionComponent, {
          class: 'modal-lg',
          backdrop: 'static',
          initialState,
        });

        return;
      case 'SelfDiagnostic':
        //open autodiagnostico
        initialState = {
          selfDiagnosticId: contextValue,
        };
        this.bsModalRef = this.modalService.show(ModalSelfDiagnosticComponent, {
          class: 'modal-lg',
          backdrop: 'static',
          initialState,
        });
        return;
      default:
        break;
    }

    const onHideModalSubscribe = this.modalService.onHide.subscribe(() => {
      onHideModalSubscribe.unsubscribe();
    });
  }
}
