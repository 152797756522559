import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/_services/http.service';
import { ImageRecognitionEmployeeSchema } from 'src/app/_schemas/image-recognition-employee-schema';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-image-recognition',
  templateUrl: './modal-image-recognition.component.html',
  styleUrls: ['./modal-image-recognition.component.scss']
})
export class ModalImageRecognitionComponent implements OnInit {

  @Input()
  employeeId: any;
  images: ImageRecognitionEmployeeSchema[];
  showLoading = true;
  showMessageNoData = false;
  @Input()
  modal: boolean;

  //charts js variables
  canvas;
  ctx;
  myChart: Chart = null;
  
  chartColors = ['rgba(255,202,5)',
  'rgb(27, 117, 187)'];

  constructor(private httpService: HttpService,
    private bsModalRef: BsModalRef,) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {

    const url = environment.endpoints.getLatestFacialDiagnostic
      .replace('{employeeId}', this.employeeId);

    this.httpService.get(url)
      .subscribe((response) => {
        this.images = response['data'];
        this.showLoading = false;

        if(this.images && this.images.length > 0) {
        this.canvas = document.getElementById("examChart");
        this.ctx = this.canvas.getContext("2d");
        this.myChart = new Chart(this.ctx, {
          type: 'line',
          data: {
            labels: this.getLabels(this.images),
            datasets: this.getDatasets(this.images)
          },
          options: {
            tooltips: {
              enabled: true,
              callbacks: {
                  label: function(tooltipItem, data) {
                      return data.datasets[tooltipItem.datasetIndex].label + ' : ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                  }
              }
          }
          }


        }
        );
      } else {
        this.showMessageNoData = true;
      }
      });
  }

  getLabels(imageRecognition: any[]) {
    let labels = [];
      imageRecognition[0].imageEmotionsList.forEach(emotion => {
        labels.push(emotion.type);
      });
    return labels;
  }

  getDatasets(imageRecognition: any[]) {
    let dataset: any = [];
    let set: any = {};
    imageRecognition.forEach(element => {
      set = {};
      set.label = moment(element.creationDate).format("D/MM/YYYY");
      set.backgroundColor = this.chartColors[0].replace(")", ",0.8)");
      set.borderColor = this.chartColors[0].replace(")", ",0.4)");
      this.chartColors.splice(0, 1);
      set.fill = false;
      let values = []
      element.imageEmotionsList.forEach(emotion => {
        values.push(Math.round(emotion.confidence));
      })
      set.data = values;
      dataset.push(set);
    });
    return dataset;
  }

  
  closeModal() {
    this.bsModalRef.hide();
  }
}
