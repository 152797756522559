import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/_services/ui.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  filterStatusOptions = [
    {
      label: 'Todos',
      code: 'all',
    },
    {
      label: 'Válidos',
      code: true,
    },
    {
      label: 'No Válidos',
      code: false,
    },
    {
      label: 'Sin especificar',
      code: null,
    },
  ];

  filterTransferOptions = [
    {
      label: 'Todos',
      code: 'all',
    },
    {
      label: 'Trasladados',
      code: true,
    },
    {
      label: 'No Trasladados',
      code: null,
    }
  ];

  years = [
    {label : 'Todos los años' , value : "all"}
  ]


  filterFuncionarios: any[];

  selectedStatus = 'all';
  selectedTransfer = 'all';
  selectedFuncionarioFilter = '';
  selectedYear = (new Date()).getFullYear() + "";

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private uiService: UiService
  ) {

    let currentYear = (new Date()).getFullYear();
    for(let i = currentYear; i >= 2018; i--){
      let yearStr = i + "";
      this.years.push({label : yearStr, value : yearStr});
    }

    this.userService.role$.subscribe(role => {
      if(role === 'RL_ADMIN_RISK_PR') {
        this.filterFuncionarios = [
          {
            label: 'Ubicación Empleados',
            code: 'employee',
          },
          {
            label: 'Riesgos Empleados',
            code: 'risk-employee',
          },
        ];
        this.selectedFuncionarioFilter = 'employee';
      }
    });


    this.userService.risks$.subscribe(risks => {
      if(risks != null && risks.length > 0){
        this.selectOption();
      }
    });

    this.userService.geoSevCalled$.subscribe(geoRisks => {
      if(geoRisks){
        this.selectOption();
      }
    });
   }

  ngOnInit() {
  }

  selectOption() {
    const filterData = {
      valid: this.selectedStatus,
      transfer: this.selectedTransfer,
      year : this.selectedYear
    };
    this.userService.filterRisks(filterData);
  }

  setFuncionarioFilter() {
    this.uiService.setFuncionarioFilter(this.selectedFuncionarioFilter);
  }

}
