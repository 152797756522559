import { Component, OnInit, NgZone, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as L from 'leaflet';
import { UserService } from 'src/app/_services/user.service';
import { UiService } from 'src/app/_services/ui.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { TokenStorageService } from 'src/app/_services/authentication/token-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GoogleMapModalComponent } from '../../../map/_components/google-map-modal/google-map-modal.component';
import { MenuSchema } from 'src/app/_schemas/menu-schema';
import { MenuOptionSchema } from 'src/app/_schemas/menu-option-schema';
import { HttpService } from 'src/app/_services/http.service';
import { Coordinate } from 'src/app/_schemas/risk-schema';

@Component({
  selector: 'app-map-employee',
  templateUrl: './map-employee.component.html',
  styleUrls: ['./map-employee.component.scss']
})
export class MapEmployeeComponent implements OnInit {

  // general map variables
  map: L.Map;
  options = {
    layers: [
      L.tileLayer(environment.maps.GOOGLE_MAPS_TILES_URL, { maxZoom: 18, attribution: 'Google', lyrs: 'p' })
    ],
    zoomControl: false,
    zoom: 6,
    center: L.latLng(5.378471, -74.256520)
  };
  @Input()
  invalidateSize: boolean;

  // leaflet-draw configuration
  drawOptions = {
    position: 'bottomleft',
    draw: {
      marker: false,
      circlemarker: false,
    }
  };
  showMapTools = false;
  // user position
  userPositionMarker: L.Marker;
  userPositionReady: boolean;
  
  // risk markers
  riskMarkers: any[] = [];
  riskPolygons: any[] = [];

  //employee coordinates
  @Input()
  employeeCoordinates: any = null;
  employeeMark: any;
  employeeMarkers: any[] = [];

  // handle modal
  bsModalRef: BsModalRef;
  canAddBusinessOrg: boolean;
  floatMenu: MenuOptionSchema[];
  floatMenuDisplay = {};

  // layers
  activeLayers: L.layerGroup;

  constructor(private userService: UserService,
    private modalService: BsModalService,
    private tokenStorageService: TokenStorageService,
    private httpService: HttpService,
    private uiService: UiService,
    private zone: NgZone,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
      let currentUrl = this.router.url;
      // if (currentUrl === '/index/home/map-organization') {
      // }
     }

  ngOnInit() {
    this.userPositionReady = false;

    this.uiService.showLayer$.subscribe( layer => {
      if ( layer ) {
        if ( ! this.activeLayers) {
          this.activeLayers = L.layerGroup([layer]);
          this.activeLayers.addTo(this.map);
        } else {
          this.activeLayers.addLayer(layer);
        }
      }
    });

    this.uiService.removeLayer$.subscribe( layer => {
      if ( layer ) {
        if ( layer === 'all' && this.activeLayers) {
            this.activeLayers.clearLayers();
        } else {
          this.activeLayers.removeLayer(layer);
        }
      }
    });
  }

  /**
 * get user position
 */
  setUserPosition() {
    const context = this;
    const currentCoordinates = this.tokenStorageService.getCoordinates();
    if (currentCoordinates && currentCoordinates.lat && currentCoordinates.lng) {
      this.addMarkerPosition(currentCoordinates);
    }

    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (objPosition) => {

          if (!context.userPositionReady) {
            // delete marker
            if (context.userPositionMarker) {
              context.map.removeLayer(context.userPositionMarker);
              context.userPositionMarker = null;
            }

            this.addMarkerPosition({ lat: objPosition.coords.latitude.toString(), lng: objPosition.coords.longitude.toString() });
            context.userPositionReady = true;
            context.tokenStorageService.setCoordinates({
              lat: objPosition.coords.latitude.toString(), lng: objPosition.coords.longitude.toString()
            });
          }
        },
        (objPositionError) => { },
        {
          maximumAge: 75000,
          timeout: 15000
        }
      );
    }
  }

  addMarkerPosition(coordinates: { lat: string, lng: string }) {
    this.userPositionMarker = L.marker(
      [coordinates.lat, coordinates.lng],
      {
        icon: L.icon({
          iconUrl: 'assets/img/markers/user-position.png',
          iconSize: [65, 65],
          iconAnchor: [32, 65],
          popupAnchor: [0, -65]
        })
      }
    );
    this.userPositionMarker.addTo(this.map);
    this.map.setView(this.userPositionMarker.getLatLng(), 15);
  }

  loadEmployeesLocations() {
    this.httpService.get(environment.endpoints.getGeoEmployeeByOrganization)
      .subscribe((res) => {
        this.clearEmployeeLocations();
        res.map(geo => {
          this.buildEmployeeMarker(geo);
        })
      });
  }

  private buildEmployeeMarker(geo) {
    L.geoJSON(geo, {
      pointToLayer: (feature, latlng) => {
        const employeeIcon = L.icon({
          iconUrl: 'assets/img/markers/user-position.png',
          iconSize: [65, 65],
          iconAnchor: [32, 65],
          popupAnchor: [0, -65]
        })
        const employeeMarker = L.marker(latlng, {
          icon: employeeIcon,
        });
        const name =  geo.properties['fullName'] ? geo.properties['fullName'] : geo.properties['email']
        employeeMarker.bindPopup(name )
        this.employeeMarkers.push(employeeMarker);
        return employeeMarker;
      }
    }).addTo(this.map);
  }

  onMapReady(map: L.Map) {
    this.map = map;
    //si se abre desde la opcion para ver la ubicacion de un solo empleado
    if (this.employeeCoordinates) { 
      L.geoJSON(this.employeeCoordinates, {
      pointToLayer: (feature, latlng) => {
        const employeeIcon = L.icon({
          iconUrl: 'assets/img/markers/user-position.png',
          iconSize: [65, 65],
          iconAnchor: [32, 65],
          popupAnchor: [0, -65]
        })
        const employeeMarker = L.marker(latlng, {
          icon: employeeIcon,
        });

        this.employeeMark = employeeMarker;
        return employeeMarker;
      }
    }).addTo(this.map);
    
    this.map.setView(this.employeeMark.getLatLng(), 15);
    }  else {
      this.uiService.mapEmployeeFilter$.subscribe( (filter: string) => {
        if ( filter === 'employee' ) {
          this.clearRisks();
          this.loadEmployeesLocations();
        } else {
          this.clearEmployeeLocations();
          this.userService.geoRisks$.subscribe( (risks: Coordinate[]) => {
            if ( risks ) {        
              this.loadUserRisks(risks);
            }
          });
        }
      });
    }

    // add custom buttons
    const context = this;
    const buttonTemplate = () => {
      const button = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom leaflet-custom-button');
      button.style.width = '32px';
      button.style.height = '32px';
      button.style.padding = '1px';
      return button;
    };

    let mainContainer;
    let buttonContainerPosition;

    const buttonOptions = {
      options: {
        position: 'topleft'
      },
      onAdd: () => {
        mainContainer = L.DomUtil.create('div', 'leaflet-control-custom');

        // button for user position
        buttonContainerPosition = buttonTemplate();
        buttonContainerPosition.title = 'Ir a la ubicación actual';
        buttonContainerPosition.onclick = () => {
          context.userPositionReady = false;
          context.setUserPosition();
        };
        const iconPosition = L.DomUtil.create('div', 'fa fa-location-arrow', buttonContainerPosition);
        iconPosition.style.fontSize = '25px';
        iconPosition.style.color = '#0cadf3';
        buttonContainerPosition.appendChild(iconPosition);
        mainContainer.appendChild(buttonContainerPosition);


        return mainContainer;
      }
    };

    const buttonsOptions = L.Control.extend(buttonOptions);
    // add zoom control
    L.control.zoom({
      position: 'topleft',
    }).addTo(map);

    map.addControl(new buttonsOptions());

    if (this.employeeCoordinates || this.invalidateSize) {
      setTimeout(() => {
        this.map.invalidateSize();
      }, 10);
    }
  }

  setMapStyles() {
    const mapStyles = {
      width: '100%',
      height: '100%'
    };
    return mapStyles;
  }

  
  loadUserRisks(risks: Coordinate[]) {
    this.clearRisks();
    risks.map( risk => {
      if ( risk.geometry.type === 'Polygon') {
        const riskPolygon = L.geoJSON(risk, {color: this.uiService.getAlertColor(risk.properties['alertLevel'])}).addTo(this.map);
        this.riskPolygons.push(riskPolygon);
      } else {
        L.geoJSON(risk, {
        pointToLayer: (feature, latlng) => {
          const riskIcon = L.icon({
            iconUrl: environment.container.url + risk.properties['marker'],
            iconSize: [50],
            iconAnchor: [40, 79],
            popupAnchor: [0, -28]
          });
          const riskMarker = L.marker(latlng, {
            icon: riskIcon,
          });

          riskMarker.on({
            click: (e) => {
                this.openRiskDetails(risk.properties['riskId']);
            },
          });
          this.riskMarkers.push(riskMarker);
          return riskMarker;
        }
      }).addTo(this.map);
    }

    });

  }

  
  clearRisks() {
    // clean risk markers
    this.riskMarkers.map((riskMarker, riskMarkersIndex) => {
      this.map.removeLayer(riskMarker);
      if ( riskMarkersIndex >= this.riskMarkers.length - 1 ) {
        this.riskMarkers = [];
      }
    });

    // clean risk polygons
    this.riskPolygons.map((riskPolygon, riskMarkersIndex) => {
      this.map.removeLayer(riskPolygon);
      if ( riskMarkersIndex >= this.riskPolygons.length - 1 ) {
        this.riskPolygons = [];
      }
    });
  }

  clearEmployeeLocations() {
    this.employeeMarkers.map((employeeMarker, employeeMarkersIndex) => {
      this.map.removeLayer(employeeMarker);
      if ( employeeMarkersIndex >= this.employeeMarkers.length - 1 ) {
        this.employeeMarkers = [];
      }
    });

  }

  
  openRiskDetails(riskId: number) {
    this.userService.setSelectedRisk(riskId);
    this.zone.run(
      () => {
        this.router.navigate([`./risk/${riskId}`], { relativeTo: this.activatedRoute })
        // this.router.navigate([`/index/home/risk/${riskId}`]);
      } 
    );
  }

}
