export class MenuOptionSchema {

    id: number;
    name: string;
    optionType: string;
    url: string;
    icon: string;
    active: string;
    menu: string;
    groupText: string;
    childs: MenuOptionSchema[];

    constructor(data?: any) {
        if (data !== undefined) {
             this.id = data.id !== undefined ? data.id : null;
             this.name = data.name !== undefined ? data.name : null;
             this.optionType = data.optionType !== undefined ? data.optionType : null;
             this.url = data.url !== undefined ? data.url : null;
             this.icon = data.icon !== undefined ? data.icon : null;
             this.active = data.active !== undefined ? data.active : null;
             this.menu = data.menu !== undefined ? data.menu : null;
             this.groupText = data.groupText !== undefined ? data.groupText : null;
             this.childs = data.childs !== undefined ? data.childs : null;
        }
    }

    static fromJS(data: any): MenuOptionSchema {
        return new MenuOptionSchema(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data.id = this.id !== undefined ? this.id : null;
        data.name = this.name !== undefined ? this.name : null;
        data.optionType = this.optionType !== undefined ? this.optionType : null;
        data.url = this.url !== undefined ? this.url : null;
        data.icon = this.icon !== undefined ? this.icon : null;
        data.active = this.active !== undefined ? this.active : null;
        data.menu = this.menu !== undefined ? this.menu : null;
        data.groupText = this.groupText !== undefined ? this.groupText : null;
        data.childs = this.childs !== undefined ? this.childs : [];
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new MenuOptionSchema(JSON.parse(json));
    }
}
