
const baseUrl = 'https://risk.azurewebsites.net/Risk-backend/';
//const baseUrl = 'http://localhost:8082/';
const baseSecurityUrl =  'https://it270-security.azurewebsites.net/risk-security/';

export const environment = {
  production: false,
  endpoints: {
    BACK_END_URL: baseUrl,
    FRONT_END_URL: 'http://localhost:4200/',

    // security
    token: baseSecurityUrl + 'oauth/token',
    userInfo: baseSecurityUrl + 'api/user/userInfo',
    menuOptions: baseSecurityUrl + 'api/user/getOptions',

    // risk
    userRisks: baseUrl + 'api/risk/creator',
    userGeoRisks: baseUrl + 'api/risk/geo/creator',
    organizationRisks: baseUrl + 'api/risk/organization',
    organizationGeoRisks: baseUrl + 'api/risk/geo/organization',
    risk: baseUrl + 'api/risk/',
    riskValidate: baseUrl + 'api/risk/validate',
    riskTransfer: baseUrl + 'api/risk/transfer',
    addRisk: baseUrl + 'api/risk/save',
    riskPublic:baseUrl +'public/risk/getByLocation?categoryId=2',
    risk_userData:baseUrl +"public/risk/getByLocation/v2?categoryId=2",

    // risk alert
    addRiskAlert: baseUrl + 'api/riskAlert/save',

    // risk recommendation
    addRiskRecommendation: baseUrl + 'api/riskRecommendation/save',

    // risk type
    riskType: baseUrl + 'api/riskType/category/',
    addRiskType: baseUrl + 'api/riskType/save',
    updateRiskType: baseUrl + 'api/riskType/update/',
    updateRiskTypeMarker:  baseUrl + 'api/riskType/image/update',

    // risk category
    organizationCategories: baseUrl + 'api/category/organization',
    allCategories: baseUrl + 'api/category/all',

    // organization
    allOrganizations: baseUrl + 'api/organization/all',
    allOrganizationsDropDown: baseUrl + 'api/organization/allDropDown',
    organization: baseUrl + 'api/organization/',
    userOrganization: baseUrl + 'api/organization/user',
    mainGeoOrganization: baseUrl + 'api/organization/geo/user',
    addOrganization:  baseUrl + 'api/organization/save',
    updateOrganization:  baseUrl + 'api/organization/update',
    updateOrganizationImage:  baseUrl + 'api/organization/image/update',
    addOrganizationEmail:  baseUrl + 'api/organizationEmail/save',
    changeStatusOrganizationEmail:  baseUrl + 'api/organizationEmail/status',
    addEntityTransfer:  baseUrl + 'api/entityTransfer/save',
    updateEntityTransfer:  baseUrl + 'api/entityTransfer/update',
    get_organization_config: baseUrl + 'public/organization/getConfig/{organizationId}',

    // employee
    getEmployeeByOrganization: baseUrl + 'api/employee/findByOrganization/{organizationId}',
    getGeoEmployeeByOrganization: baseUrl + 'api/employee/geo/employees',
    getTaskInstancesByEmployee: baseUrl + 'api/employee',
    getHealthSurveyById: baseUrl + 'api/employee/getHealthSurvey?id=',
    getSelfDiagnosticById: baseUrl + 'api/employee/getSelfDiagnostic?id=',
    getQrCodeDetails: baseUrl + 'public/employee/{employeeId}/codeQR/details',
    getQrCode: baseUrl + 'public/employee/{employeeId}/codeQR/{size}',
    initDataSelfDiagnostic: baseUrl + 'public/employee/initDataSelfDiagnostic',
    initDataHealthCondition: baseUrl + 'public/employee/initData/{organizationId}',
    getLastImageRecognition: baseUrl + 'api/employee/getLastImageRecognition?employeeId=',
    getByUsername: baseUrl + 'api/employee/getByUsername?username=',
    createEmployee: baseUrl + 'api/employee/saveListToOrganization/{organizationId}',

    // task
    getTaskLogByUser: baseUrl + 'api/task/getTaskLogByAssignTo?user=',
    getTaskByAssignTo: baseUrl + 'api/task/getTaskAssignTo/{assignTo}',
    updateStatusTasks: baseUrl + 'api/task/updateStatusTasks',
    getTaskByCodeOfDependentUsersAndFatherByFather: baseUrl + 'api/task/getTaskByCodeOfDependentUsersAndFatherByFather?user={user}&code={code}',

    // organization area
    addOrganizationArea: baseUrl + 'api/organizationArea/save/',
    updateOrganizationArea: baseUrl + 'api/organizationArea/update/',
    removeOrganizationArea: baseUrl + 'api/organizationArea/remove/',
    uploadOrganizationAreaFile: baseUrl + 'api/organizationArea/file/upload',

    // dashboard
    getSelfDiagnosticTimeLine: baseUrl + 'api/dashboard/selfDiagnosticTimeLine',
    getEmployeesAtRisk: baseUrl + 'api/dashboard/employeesAtRisk',
    getGeneralStats: baseUrl + 'api/dashboard/generalStats',
    getEmployeesInfo: baseUrl + 'api/dashboard/getEmployeesInfo',
    getMeets: baseUrl + 'api/employee/{userAccountId}/getMeets/{date}',
    getLatestFacialDiagnostic: baseUrl + 'api/dashboard/getLatestFacialDiagnostic?employeeId={employeeId}',
    getSelfDiagnosticSummary:  baseUrl + 'api/dashboard/getSelfDiagnosticSummary/{initialDate}/{endDate}',
    getEmployeesWithAutodiag:  baseUrl + 'api/wellness/employee/getEmployeesWithAutodiag/{initialDate}/{endDate}',
    getHealthConditionSummary:  baseUrl + 'api/dashboard/getHealthConditionSummary/{initialDate}/{endDate}',
    powerBiToken: baseUrl + 'api/powerBi/token',

    // report
    downloadReport: baseUrl + 'api/reports/download',

    // file
    imagePreview: baseUrl + 'public/file/image/preview/%size%/',
    filePreview: baseUrl + 'public/file/preview',

    // location
    territorialUnitLayers: baseUrl + 'api/map/territorialUnitLayers',
    departments: baseUrl + 'api/map/departments',
    municipalities: baseUrl + 'api/map/departments/%departmentId%/municipalities',
    count_biological_by_location : baseUrl + 'public/biological/location/{locationCode}/count',

    // logs
    history: baseUrl + 'api/log/history',

    // charts
    chart: {
      getRisksGroupRiskTypes: baseUrl + 'api/chart/risks/riskTypes',
      getRisksGroupDepartment: baseUrl + 'api/chart/risks/departments',
      getRisksGroupUsers: baseUrl + 'api/chart/risks/users',
      incidencesByYearRange: baseUrl + 'api/chart/incidences/%fromYear%/%untilYear%/?'
    },

    // user
    getUsersByOrganization: baseUrl + 'api/organizationUser/organization/',
    getAllUsers: baseSecurityUrl + 'api/user/findAll',
    updateUser: baseSecurityUrl + 'api/user/edit',
    createUser: baseSecurityUrl + 'public/api/account/create',

    // lead
    saveLead: baseUrl + 'public/lead/save',

    // IoT
    userDevices: baseUrl + 'api/device/creator',
    deviceValues: baseUrl + 'public/api/getDeviceValues',

    // employees
    premium_organizations: baseUrl + 'public/organization/premium',
    save_employee: baseUrl + 'public/employee/save',
    verify_employee: baseUrl + 'public/employee/verify',
    user_data_mobile_save : baseUrl + 'public/userDataMobile/save',

    user_tasks: baseUrl + 'api/task/getTaskAssignTo',
    get_employee_status: baseUrl + 'api/employee/codeQR/details',
    get_qr_details: baseUrl + 'public/wellness/employee/codeQR/details/{employeeId}',
    get_employee_qr_wellneess: baseUrl + 'public/employee/{employee_id}/codeQR/250',
    get_employee_qr: baseUrl + 'api/employee/codeQR/250',
    get_employee_data: baseUrl + 'api/employee/get',
    save_employee_data_policy_consent: baseUrl + 'api/employee/saveDataPolicyConsent',
    save_employee_residence: baseUrl + 'api/employee/saveHomeCoordinates',
    employee_initialData: baseUrl + 'public/wellness/employee/{employeeId}/initData/{organizationId}',
    save_employee_survey: baseUrl + 'public/wellness/employee/saveHealthSurvey',
    save_employee_survey_familiar: baseUrl + 'public/wellness/employee/saveHealthSurveyFamiliar',
    self_diagnostic_initialData: baseUrl + 'public/wellness/employee/initDataSelfDiagnostic',
    save_self_diagnostic: baseUrl + 'public/wellness/employee/saveSelfDiagnostic',
    getTaskByCodeOfDependentUsersByFather: baseUrl +'public/task/getTaskByCodeOfDependentUsersByFather?user={user}&code={code}',
    cleanCache: baseUrl + 'public/cache/clean',

    wellness: {
      //public
      getQrCodeDetails: baseUrl + 'public/wellness/employee/{employeeId}/codeQR/details',
      getQrCode: baseUrl + 'public/wellness/employee/{employeeId}/codeQR/{size}',
      initDataHealthCondition: baseUrl + 'public/wellness/employee/initData/{organizationId}',
      initDataSelfDiagnostic: baseUrl + 'public/wellness/employee/initDataSelfDiagnostic',
      //secured
      getSelfDiagnosticTimeLine: baseUrl + 'api/wellness/employee/selfDiagnosticTimeLine',
      getEmployeesAtRisk: baseUrl + 'api/wellness/employee/employeesAtRisk',
      getGeneralStats: baseUrl + 'api/wellness/employee/generalStats',
      getEmployeesInfo: baseUrl + 'api/wellness/employee/getEmployeesInfo',
      getHealthConditionSummary:  baseUrl + 'api/wellness/employee/getHealthConditionSummary/{initialDate}/{endDate}',
      getSelfDiagnosticSummary:  baseUrl + 'api/wellness/employee/getSelfDiagnosticSummary/{initialDate}/{endDate}',
      getEmployeesWithAutodiag:  baseUrl + 'api/wellness/employee/getEmployeesWithAutodiag/{initialDate}/{endDate}',

      sendVerificationCodeEmail: baseUrl + 'public/wellness/employee/sendEmailByEmployee/{employeeId}',
      createEmployee: baseUrl + 'api/employee/createUserForOrganization/{organizationId}/{sendEmail}',

    }
  },
  youtube: {
    playlistItems: 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId='
                    + 'PLb4SAyuMb2Ujyz5vdTbreQz0UoxOCsUJX&maxResults=25&key=AIzaSyD2wmsuepT4YdkHJ60-5_ahqivnZSvvobI',
    embed: 'https://www.youtube.com/embed/'
  },
  maps: {
    GOOGLE_MAPS_TILES_URL: 'https://mt0.google.com/vt/lyrs={lyrs}&z={z}&x={x}&y={y}',
    geoserver: 'https://informariesgos.com/geoserver/Base_de_Datos/wms',
    geoserver_dane: 'http://geoportal.dane.gov.co/mparcgis/services/INDICADORES_CTERRITORIO/'
              + 'Cache_MpiosCTDeptosAM_DivisionPolitica_2012/MapServer/WMSServer?',
    geoserver_riesgos: 'http://informariesgos.com:8080/geoserver/DatosEspacialesShp/ows?',

  },
  regex: {
    email: '[a-zA-Z0-9.!#$%&’*+/=?^_\\-`{|}~-ñ]+@[a-zA-Z0-9-ñ]+(?:\\.[a-zA-Z0-9-]+)*',
    username: '^[a-z0-9_-]{8,15}$',
    password: '.{6,300}'
  },
  validationMessages: {
    input: 'Campo inválido',
    username: 'Campo inválido. No debe tener espacios ni caracteres especiales y debe tener entre 7 y 15 caracteres',
    password: 'Campo inválido. Debe tener más de 5 caracteres',
    terms: 'Debe aceptar términos y condiciones para crear una cuenta'
  },
  recaptcha: {
    sitekey: '6LdKZMYZAAAAAKMVLa1bmSAozx9aW4zzr05ErCdV'
  },
  container: {
    url : 'https://filesrisk.blob.core.windows.net/riskcontainer/'
  }
};
