import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  @Output() returnModalResponseEvent = new EventEmitter<string>();
  @Input() modalDataInput: any;

  modalData: {
    message: string;
    okBtnText: string;
    cancelBtnText: string;
  };

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.modalData = this.modalDataInput;
  }

  /**
   * confirm modal
   */
  confirm() {
    this.returnModalResponseEvent.emit('ok');
    this.bsModalRef.hide();
  }

  /**
   * cancel modal
   */
  decline() {
    this.returnModalResponseEvent.emit('cancel');
    this.bsModalRef.hide();
  }

}
