import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OrganizationSchema } from 'src/app/_schemas/organization-schema';
import { HttpService } from 'src/app/_services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-user-to-organization',
  templateUrl: './add-user-to-organization.component.html',
  styleUrls: ['./add-user-to-organization.component.scss']
})
export class AddUserToOrganizationComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  userForm: FormGroup;
  displayedColumns: string[] = ['email', 'fullName', 'actions'];
  dataSource: MatTableDataSource<any>;
  userList = [];
  organizations = [];
  organizationIdSelected = null;
  showForm = true;
  usersCreated = [];
  isLoading = false;
  sendEmailWithCode: Boolean = true;

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private httpService: HttpService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService) {
  }

  ngOnInit() {
    this.initForm();
    this.dataSource = new MatTableDataSource(this.userList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getOrganizations();
  }

  addToList() {
    if (this.userForm.invalid) {
      this.toastr.warning('Debe ingresar correo electrónico');
      return;
    }

    this.userList.push(this.userForm.getRawValue()),
      this.userForm.reset();
    this.dataSource.data = this.userList;
  }

  private initForm() {
    this.userForm = this.formBuilder.group({
      fullName: [''],
      email: ['']
    });
  }

  getOrganizations() {
    this.httpService.get(environment.endpoints.allOrganizationsDropDown)
      .subscribe((res: OrganizationSchema[]) => {
        this.organizations = res;
      });
  }

  deleteFromList(email) {
    this.userList.splice(this.findUserIndexByEmail(email), 1);
    this.dataSource.data = this.userList;
  }

  private findUserIndexByEmail(email) {
    const index = this.userList.findIndex(child => child.email === email);
    return index;
  }

  get f() { return this.userForm.controls; }

  create() {
    if(!this.organizationIdSelected) {
      this.toastr.warning('Debe seleccionar una organización.')
      return;
    }
    if(!this.userList) {
      this.toastr.warning('Adicione al menos un usuario')
      return;
    }
    this.isLoading = true;
    const url = environment.endpoints.wellness.createEmployee.replace('{organizationId}', this.organizationIdSelected).replace('{sendEmail}',this.sendEmailWithCode.toString());
    this.httpService.save(url,
      this.userList).subscribe( async (response) => {
        if ( response['status'] === 'success' ) {
          this.showForm = false;
          this.usersCreated = response['data'];
        } else {
          this.toastr.error('Algo malo pasó, por favor intenta de nuevo.');
        }
        
        this.isLoading = false;
      }, (e) => {
        this.isLoading = false;
        this.toastr.error('Error del servidor, por favor intenta de nuevo.');
       }
    );
  }

 
  closeModal() {
    this.bsModalRef.hide();
    this.modalService._hideModal(1);
  }

}
