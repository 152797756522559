import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { OrganizationSchema } from 'src/app/_schemas/organization-schema';
import { FileUploader } from 'ng2-file-upload';
import { BsModalRef } from 'ngx-bootstrap';
import { HttpService } from 'src/app/_services/http.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-organization-area',
  templateUrl: './add-organization-area.component.html',
  styleUrls: ['./add-organization-area.component.scss']
})
export class AddOrganizationAreaComponent implements OnInit {
  @Output() reloadOrganizationEvent = new EventEmitter<OrganizationSchema>();

  organizationId: number;

  name: string;
  description: string;
  userResponsable: string;
  emailUserResponsable: string;

  showProgressBar: boolean;

  // file uploader variables
  public uploader: FileUploader;
  public hasBaseDropZoneOver;
  uploaderResponse: string;

  constructor(
    public bsModalRef: BsModalRef,
    private cd: ChangeDetectorRef,
    private httpService: HttpService,
    private toastr: ToastrService,
  ) {
    this.uploader = new FileUploader({
      url: environment.endpoints.BACK_END_URL,
      allowedMimeType: ['image/png', 'image/jpeg', 'audio/mp3', 'audio/mpeg', 'video/mp4', 'video/mpeg'],
      maxFileSize: 10 * 1024 * 1024,
      queueLimit: 5,
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise( (resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });

    this.hasBaseDropZoneOver = false;
    this.uploaderResponse = '';
    this.uploader.response.subscribe( res => this.uploaderResponse = res );
  }

  ngOnInit() {
    this.showProgressBar = false;
  }

  afterModelLoad(): void {
    this.cd.detectChanges();
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  onSubmit() {
    this.showProgressBar = true;
    this.cd.detectChanges();

    const formData = new FormData();
    formData.append('organizationId', this.organizationId.toString());
    formData.append('name', this.name);
    if ( this.description ) {
      formData.append('description', this.description);
    }
    if ( this.userResponsable ) {
      formData.append('userResponsable', this.userResponsable);
    }
    if ( this.emailUserResponsable ) {
      formData.append('emailUserResponsable', this.emailUserResponsable);
    }

    for (let i = 0; i < this.uploader.queue.length; i++) {
      const element = this.uploader.queue[i];
      formData.append('files', element._file);
    }

    this.httpService.saveFD(environment.endpoints.addOrganizationArea, formData).subscribe( (res) => {
      this.toastr.success('Area guardada correctamente');
      this.reloadOrganizationEvent.emit(res.data);
      this.showProgressBar = false;
      this.cd.detectChanges();
      this.bsModalRef.hide();
    },
    (error) => {
      this.toastr.error('Error del servidor');
      this.showProgressBar = false;
      this.cd.detectChanges();
    });

  }

  // detect drag & drop mouse cursor
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
    this.cd.detectChanges();
  }

  // patches for redraw modal data
  onChange(e: Event) {
    this.cd.detectChanges();
  }
  clearQueue() {
    this.uploader.clearQueue();
    this.cd.detectChanges();
  }
  removeItem(item) {
    item.remove();
    this.cd.detectChanges();
  }

}
